
import _ from "lodash";
import Vue from "vue";
import CommonComponents from "@/views/shared";

import { mapState, mapGetters } from "vuex";
import * as ActionTypes from "@/store/actions";
import { ExportDataAction, ExportParams } from "@/store/typed-actions";
import { GlobalState } from "@/store/modules/global";
import { ExportStatus } from "@/api/api";

interface Data {
    downloaded: { [index: number]: boolean };
}

export default Vue.extend({
    name: "ExportPanel",
    components: {
        ...CommonComponents,
    },
    props: {
        bookmark: {
            type: Object,
            required: true,
        },
        containerClass: {
            type: String,
            required: true,
        },
    },
    data: (): Data => {
        return {
            downloaded: {},
        };
    },
    computed: {
        ...mapState({
            history: (gs: GlobalState) => {
                if (gs.exporting.history) {
                    return _.reverse(_.sortBy(Object.values(gs.exporting.history), (de) => de.createdAt));
                }
                return [];
            },
        }),
    },
    mounted() {
        return this.$store.dispatch(ActionTypes.NEED_EXPORTS);
    },
    methods: {
        isDownloaded(this: any, id: number): boolean {
            return this.downloaded[id] === true;
        },
        prettyKind(kind: string): string {
            const map = {
                csv: "CSV",
                "json-lines": "JSON Lines",
            };
            return map[kind] || "Unknown";
        },
        async onClose(): Promise<void> {
            this.$emit("close");
        },
        async onExportCSV(): Promise<void> {
            console.log("viz: exporting");
            await this.$store.dispatch(new ExportDataAction(this.bookmark, new ExportParams({ csv: true })));
        },
        async onExportJSONLines(): Promise<void> {
            console.log("viz: exporting");
            await this.$store.dispatch(new ExportDataAction(this.bookmark, new ExportParams({ jsonLines: true })));
        },
        onDownload(de: ExportStatus): void {
            console.log("viz: download", de);
            Vue.set(this.downloaded, de.id, true);
        },
    },
});
