
import _ from "lodash";
import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "StationPickerStation",
    props: {
        station: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        location(this: any) {
            const gps = [this.station.location?.latitude, this.station.location?.longitude]
                .filter((v) => v)
                .map((v) => v.toFixed(3))
                .join(", ");
            const locations = [gps, "Unknown"];
            return _(locations)
                .compact()
                .first();
        },
        status(this: any) {
            if (this.station.deployedAt) {
                return this.$t('station.deployed');
            }
          return this.$t('station.readyToDeploy');
        },
    },
    methods: {
        onClicked() {
            this.$emit("selected");
        },
    },
});
