
import Vue from "vue";
import CommonComponents from "@/views/shared";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";
import * as ActionTypes from "@/store/actions";
import { DisplayStation } from "@/store";

export default Vue.extend({
    name: "ProfileForm",
    components: {
        ...CommonComponents,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data(): {
        loading: boolean;
        form: {
            id: number;
            name: string;
            email: string;
            bio: string;
            image: any;
            publicProfile: boolean;
        };
        changePasswordForm: {
            existing: string;
            password: string;
            passwordConfirmation: string;
        };
        notifySaved: boolean;
        notifyPasswordChanged: boolean;
        emailAvailable: boolean;
    } {
        return {
            loading: false,
            form: {
                id: this.user.id,
                name: this.user.name,
                email: this.user.email,
                bio: this.user.bio,
                image: null,
                publicProfile: false,
            },
            changePasswordForm: {
                existing: "",
                password: "",
                passwordConfirmation: "",
            },
            notifySaved: false,
            notifyPasswordChanged: false,
            emailAvailable: true,
        };
    },
    validations: {
        form: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            bio: {},
        },
        changePasswordForm: {
            existing: {
                required,
                min: minLength(10),
            },
            password: { required, min: minLength(10) },
            passwordConfirmation: { required, min: minLength(10), sameAsPassword: sameAs("password") },
        },
    },
    methods: {
        goBack(): void {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
        },
        showStation(station: DisplayStation): Promise<any> {
            return this.$router.push({ name: "mapStation", params: { id: String(station.id) } });
        },
        saveForm(): void {
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) {
                console.log("save form, validation error");
                return;
            }

            this.$emit("save", this.form);
        },
        changePassword(): void {
            this.$v.changePasswordForm.$touch();
            if (this.$v.changePasswordForm.$pending || this.$v.changePasswordForm.$error) {
                console.log("password form, validation error");
                return;
            }

            this.$emit("change-password", this.changePasswordForm);
        },
        onImage(image): void {
            this.form.image = image;
        },
    },
});
