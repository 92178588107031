
import Vue, { PropType } from "vue";
import { Project } from "@/api";
import FollowControl from "./FollowControl.vue";

export default Vue.extend({
    name: "FollowPanel",
    components: {
        FollowControl,
    },
    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },
});
