var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-wrap",class:{ 'hide-markers': !_vm.showStations }},[_c('mapbox',{staticClass:"stations-map",attrs:{"access-token":_vm.mapbox.token,"map-options":{
            style: _vm.mapbox.style,
            bounds: _vm.bounds,
            zoom: 10,
        },"nav-control":{
            show: !_vm.isMobileView,
            position: 'bottom-left',
        }},on:{"map-init":_vm.onMapInitialized,"map-load":_vm.onMapLoaded,"zoomend":_vm.newBounds,"dragend":_vm.newBounds}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }