
import Vue from "vue";

export default Vue.extend({
    name: "DoubleHeader",
    props: {
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        backTitle: {
            type: String,
            default: null,
        },
        backRoute: {
            type: String,
            default: null,
        },
        backRouteParams: {
            type: Object,
            default: undefined,
        },
    },
    methods: {
        async onBack() {
            if (this.backRoute) {
                await this.$router.push({ name: this.backRoute, params: this.backRouteParams });
            } else {
                this.$emit("back");
            }
        },
    },
});
