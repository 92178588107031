
import Vue from "vue";
import Spinner from "@/views/shared/Spinner.vue";

export default Vue.extend({
    name: "AuthenticatedPhoto",
    components: {
        Spinner,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        processing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            photo: null,
        };
    },
    watch: {
        url(this: any) {
            return this.refresh();
        },
    },
    created(this: any) {
        return this.refresh();
    },
    methods: {
        refresh(this: any) {
            return this.$services.api.loadMedia(this.url).then((photo) => {
                this.photo = photo;
            });
        },
    },
});
