
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import * as ActionTypes from "@/store/actions";
import { MarkNotificationsSeen } from "@/store";
import CommonComponents from "@/views/shared";
import NotificationsList from "@/views/notifications/NotificationsList.vue";
import { GlobalState } from "@/store/modules/global";
import Logo from "@/views/shared/Logo.vue";
import { Notification } from "@/store/modules/notifications";
import { isMobile } from "@/utilities";
import LanguageSelector from "@/views/shared/LanguageSelector.vue";

export default Vue.extend({
    name: "HeaderBar",
    components: {
        LanguageSelector,
        ...CommonComponents,
        NotificationsList,
        Logo,
    },
    data(): { isAccountHovered: boolean } {
        return {
            isAccountHovered: false,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "isAuthenticated",
            notifications: "notifications",
            numberOfUnseenNotifications: "numberOfUnseenNotifications",
        }),
        ...mapState({ user: (s: GlobalState) => s.user.user }),
        firstName(): string {
            if (!this.user) {
                return "";
            }
            return this.user.name.split(" ")[0];
        },
    },
    methods: {
        async logout(): Promise<void> {
            await this.$store.dispatch(ActionTypes.LOGOUT).then(() => {
                // Action handles where we go after.
            });
        },
        onAccountHover(): void {
            // console.log("hover", this.hiding, this.isAccountHovered);
            if (!isMobile()) {
                this.isAccountHovered = !this.isAccountHovered;
            }
        },
        onAccountHoverOut(): void {
            this.isAccountHovered = false;
        },
        onAccountClick(): void {
            if (isMobile()) {
                this.isAccountHovered = !this.isAccountHovered;
            }
        },
        async markAllSeen(): Promise<void> {
            await this.$store.dispatch(new MarkNotificationsSeen([]));
        },
        viewAll() {
            return this.$router.push({ name: "notifications" });
        },
        notificationNavigate(notification: Notification) {
            if (notification.projectId) {
                return this.$router
                    .push({
                        name: "viewProject",
                        params: { id: notification.projectId },
                        hash: `#comment-id-${notification.postId}`,
                    })
                    .then(() => {
                        this.$store.dispatch(new MarkNotificationsSeen([notification.notificationId]));
                    })
                    .catch((err) => {
                        return;
                    });
            }
            if (notification.bookmark) {
                return this.$router
                    .push({
                        name: "exploreBookmark",
                        query: { bookmark: notification.bookmark },
                        hash: `#comment-id-${notification.postId}`,
                    })
                    .then(() => {
                        this.$store.dispatch(new MarkNotificationsSeen([notification.notificationId]));
                    })
                    .catch((err) => {
                        return;
                    });
            }
        },
    },
});
