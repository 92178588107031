
import Vue, { PropType } from "vue";
import { mapGetters, mapState } from "vuex";
import { GlobalState } from "@/store/modules/global";
import UserPhoto from "../shared/UserPhoto.vue";
import moment from "moment";
import { Notification } from "@/store/modules/notifications";

export default Vue.extend({
    name: "NotificationsList",
    components: {
        UserPhoto,
    },
    data(): {
        notificationsData: Notification[];
    } {
        return {
            notificationsData: [],
        };
    },
    computed: {
        ...mapGetters({
            notifications: "notifications",
        }),
        ...mapState({ user: (s: GlobalState) => s.user.user }),
        notificationsVisible() {
            return this.notificationsList ?? this.notifications;
        },
    },
    props: {
        notificationsList: {
            type: Array as PropType<Notification[]>,
            required: false,
        },
    },
    methods: {
        display(notification: Notification): string {
            switch (notification.kind) {
                case "reply":
                    return this.$tc("notifications.userReplied");
                case "comment":
                    return this.$tc("notifications.userCommented");
                case "mention":
                    return this.$tc("notifications.userMentioned");
            }

            return "Notification";
        },
        onClick(notification: Notification): void {
            this.$emit("notification-click", notification);
        },
        formatTimestamp(timestamp: number): string {
            return moment(timestamp).fromNow();
        },
    },
});
