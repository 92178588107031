
export interface ListItemOption {
    label: string;
    event: string;
    icon?: string;
}

import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "ListItemOptions",
    props: {
        options: {
            type: Array as PropType<ListItemOption[]>,
            required: true,
        },
    },
    methods: {
        show(event: MouseEvent): void {
            if (event.target) {
                const optionsMenu = (event.target as HTMLElement).firstElementChild;

                if (!optionsMenu) {
                    return;
                }

                if (!(optionsMenu as HTMLElement).classList.contains("visible")) {
                    (optionsMenu as HTMLElement).classList.add("visible");
                    setTimeout(function() {
                        document.addEventListener(
                            "click",
                            function() {
                                (optionsMenu as HTMLElement).classList.remove("visible");
                            },
                            {
                                once: true,
                            }
                        );
                    }, 1);
                }
            }
        },
    },
});
