
import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import StandardLayout from "../StandardLayout.vue";
import { GlobalState } from "@/store/modules/global";
import NotificationsList from "@/views/notifications/NotificationsList.vue";
import { Notification } from "@/store/modules/notifications";

export default Vue.extend({
    name: "NotificationsView",
    components: {
        NotificationsList,
        StandardLayout,
    },
    data() {
        return {
            filter: "",
        };
    },
    computed: {
        ...mapGetters({
            notifications: "notifications",
        }),
        ...mapState({ user: (s: GlobalState) => s.user.user }),
        notificationsList(): Notification[] {
            return this.filter ? this.notifications.filter((item) => item.kind === this.filter) : this.notifications;
        },
    },
    methods: {
        viewAll() {
            this.filter = "";
        },
        viewKind(kind: string) {
            this.filter = kind;
        },
        notificationNavigate(notification: Notification) {
            if (notification.projectId) {
                return this.$router
                    .push({
                        name: "viewProject",
                        params: { id: notification.projectId },
                        hash: `#comment-id-${notification.postId}`,
                    })
                    .catch((err) => {
                        return;
                    });
            }
            if (notification.bookmark) {
                return this.$router
                    .push({
                        name: "exploreBookmark",
                        query: { bookmark: notification.bookmark },
                        hash: `#comment-id-${notification.postId}`,
                    })
                    .catch((err) => {
                        return;
                    });
            }
        },
    },
});
