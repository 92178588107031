
import CommonComponents from "@/views/shared";
import FollowControl from "@/views/shared/FollowControl.vue";
import * as ActionTypes from "@/store/actions";

export default {
    name: "ProjectThumbnail",
    components: {
        ...CommonComponents,
        FollowControl,
    },
    props: {
        project: {
            type: Object,
            required: true,
        },
        invited: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: true,
            accepted: false,
        };
    },
    methods: {
        getImageUrl(this: any, project) {
            return this.$config.baseUrl + project.photo;
        },
        onAccept(this: any, ev: any) {
            console.log("accept", ev);
            return this.$store.dispatch(ActionTypes.ACCEPT_PROJECT, { projectId: this.project.id }).then(() => {
                this.accepted = true;
                this.visible = false;
            });
        },
        onDecline(this: any, ev: any) {
            console.log("decline", ev);
            return this.$store.dispatch(ActionTypes.DECLINE_PROJECT, { projectId: this.project.id }).then(() => {
                this.visible = false;
            });
        },
    },
};
