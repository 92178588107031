
import Vue from "vue";
import {getPartnerCustomizationWithDefault} from '@/views/shared/partners';

export default Vue.extend({
    name: "ValueMarker",
    props: {
        color: {
            type: String,
            default: getPartnerCustomizationWithDefault().latestPrimaryNoDataColor,
        },
        value: {
            type: Number,
        },
        id: {
            type: Number,
        },
    },
    methods: {
        onClick() {
            this.$emit("marker-click", { id: this.id });
        },
    },
});
