
import Vue from "vue";
import CommonComponents from "@/views/shared";
import FKApi, { SimpleUser } from "@/api/api";

import "vue-multiselect/dist/vue-multiselect.min.css";

export default Vue.extend({
    name: "UserPicker",
    components: { ...CommonComponents },
    props: {},
    data(): {
        busy: boolean;
        users: SimpleUser[];
        selected: SimpleUser[];
    } {
        return {
            busy: false,
            users: [],
            selected: [],
        };
    },
    methods: {
        async search(query: string): Promise<void> {
            this.busy = true;
            if (query.length == 0) {
                this.users = [];
            } else {
                const response = await new FKApi().adminSearchUsers(query);
                this.users = response.users;
            }
            this.busy = false;
        },
        select(user: SimpleUser): void {
            this.$emit("select", user);
        },
    },
});
