
import Vue, { PropType } from "vue";
import { CurrentUser, DisplayProject, DisplayStation, Project, ProjectModule, ProjectUser } from "@/store";
import CommonComponents from "@/views/shared";
import ProjectStations from "./ProjectStations.vue";
import ProjectDataFiles from "./ProjectDataFiles.vue";
import StationsReadings from "./StationsReadings.vue";
import Comments from "../comments/Comments.vue";
import TeamManager from "./TeamManager.vue";
import * as utils from "../../utilities";
import { twitterCardMeta } from "@/social";

export default Vue.extend({
    name: "ProjectAdmin",
    components: {
        ...CommonComponents,
        ProjectStations,
        ProjectDataFiles,
        StationsReadings,
        TeamManager,
        Comments,
    },
    metaInfo() {
        return {
            meta: twitterCardMeta(this.displayProject),
            afterNavigation() {
                console.log("hello: after-navigation");
            },
        };
    },
    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            required: true,
        },
        displayProject: {
            type: Object as PropType<DisplayProject>,
            required: true,
        },
        userStations: {
            type: Array as PropType<DisplayStation[]>,
            required: true,
        },
    },
    data: (): { viewingActivityFeed: boolean } => {
        return {
            viewingActivityFeed: false,
        };
    },
    computed: {
        project(): Project {
            return this.displayProject.project;
        },
        projectModules(this: any): { name: string; url: string }[] {
            return this.displayProject.modules.map((m) => {
                return {
                    name: m.name,
                    url: this.getModuleImg(m),
                };
            });
        },
    },
    methods: {
        getProjectUserImage(projectUser: ProjectUser): string | null {
            if (projectUser.user.photo) {
                return this.$config.baseUrl + "/" + projectUser.user.photo.url;
            }
            return null;
        },
        openProjectNotes(this: any): void {
            this.$router.push({ name: "viewProjectNotes", params: { projectId: `${this.project.id}` } });
        },
        editProject(this: any): void {
            this.$router.push({ name: "editProject", params: { id: `${this.project.id}` } });
        },
        addUpdate(this: any): void {
            this.$router.push({ name: "addProjectUpdate", params: { project: `${this.project}` } });
        },
        viewProfile(): void {
            this.$emit("viewProfile");
        },
        closeActivityFeed(): void {
            this.viewingActivityFeed = false;
        },
        openActivityFeed(): void {
            this.viewingActivityFeed = true;
        },
        getModuleImg(module: ProjectModule): string {
            return this.$loadAsset(utils.getModuleImg(module));
        },
        // tooltip is shown via CSS on desktop, the js makes sure the tooltip doesn't overflow the screen on mobile
        showTooltip(ref: string): void {
            const refs = this.$refs[ref];
            const el = refs ? refs[0] : null;

            if (!utils.isMobile() || !el) {
                return;
            }

            const boundingRect = el.getBoundingClientRect();
            const buffer = 15;
            const elHalfWidth = boundingRect.width / 2;
            const screenWidth = window.screen.availWidth;

            if (boundingRect.left < 0 + buffer) {
                el.style.left = elHalfWidth + "px";
                el.style.right = "unset";
            }

            if (boundingRect.right > screenWidth) {
                el.style.left = "unset";
                el.style.right = -elHalfWidth + "px";
            }
        },
    },
});
