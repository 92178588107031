
import { mapState, mapGetters } from "vuex";
import * as ActionTypes from "@/store/actions";
import { GlobalState } from "@/store/modules/global";
import { DisplayStation, MappedStations } from "@/store";
import { SensorDataQuerier } from "./shared/sensor_data_querier";

import Vue, { PropType } from "vue";
import StandardLayout from "./StandardLayout.vue";
import StationHoverSummary from "./shared/StationHoverSummary.vue";
import StationsMap from "./shared/StationsMap.vue";
import TinyChart from "@/views/viz/TinyChart.vue";
import SnackBar from "@/views/shared/SnackBar.vue";
import MapViewTypeToggle from "@/views/shared/MapViewTypeToggle.vue";
import { MapViewType } from "@/api/api";

export default Vue.extend({
    name: "StationsView",
    components: {
        StandardLayout,
        StationsMap,
        StationHoverSummary,
        TinyChart,
        MapViewTypeToggle,
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        bounds: {
            type: Array as unknown as PropType<[[number, number], [number, number]]>,
            required: false,
        },
    },
    data(): {
        showNoStationsMessage: boolean;
        layoutChanges: number;
        sensorDataQuerier: SensorDataQuerier;
    } {
        // console.log("stations-view:data", this.stations);
        return {
            showNoStationsMessage: true,
            layoutChanges: 0,
            sensorDataQuerier: new SensorDataQuerier(this.$services.api),
        };
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            hasNoStations: (s: GlobalState) => s.stations.hasNoStations,
            stations: (s: GlobalState) => Object.values(s.stations.user.stations),
            userProjects: (s: GlobalState) => Object.values(s.stations.user.projects),
            anyStations: (s: GlobalState) => Object.values(s.stations.user.stations).length > 0,
        }),
        activeStation(): DisplayStation {
            return this.$state.stations.stations[this.id];
        },
        mapped(): MappedStations | null {
            if (!this.$getters.mapped) {
                return null;
            }
            if (this.bounds) {
                console.log(`focusing bounds: ${this.bounds}`);
                return this.$getters.mapped.overrideBounds(this.bounds);
            }
            if (this.id) {
                console.log(`focusing station: ${this.id}`);
                return this.$getters.mapped.focusOn(this.id);
            }
            return this.$getters.mapped;
        },
        viewType(): MapViewType {
            if (this.$route.meta?.viewType) {
                return this.$route.meta.viewType;
            }
            return MapViewType.map;
        },
    },
    beforeMount(): Promise<any> {
        if (this.id) {
            return this.$store.dispatch(ActionTypes.NEED_STATION, { id: this.id });
        }
        return Promise.resolve();
    },
    watch: {
        stations() {
            // console.log("stations-view:stations", this.stations);
            this.sensorDataQuerier = new SensorDataQuerier(this.$services.api);
        },
        id(): Promise<any> {
            if (this.id) {
                return this.$store.dispatch(ActionTypes.NEED_STATION, { id: this.id });
            }
            return Promise.resolve();
        },
    },
    methods: {
        goBack(): void {
            if (window.history.length) {
                this.$router.go(-1);
            } else {
                this.$router.push("/");
            }
        },
        boundsParam(): string | null {
            const mapped = this.mapped;
            if (!mapped || !mapped.bounds) return null;
            return JSON.stringify([mapped.bounds.min, mapped.bounds.max]);
        },
        async showSummary(params: { id: number }): Promise<void> {
            if (this.id != params.id) {
                const bounds = this.boundsParam();
                if (bounds) {
                    console.log(`clicked station, showing: ${params.id}`);
                    await this.$router.push({
                        name: "mapStationBounds",
                        params: {
                            id: String(params.id),
                            bounds: bounds,
                        },
                    });
                }
            }
        },
        async closeSummary(): Promise<void> {
            const bounds = this.boundsParam();
            if (bounds) {
                await this.$router.push({
                    name: "mapAllStationsBounds",
                    params: {
                        bounds: bounds,
                    },
                });
            }
        },
        layoutChange() {
            this.layoutChanges++;
        },
    },
});
