
import Vue, { PropType } from "vue";

interface UserPhoto {
    updatedAt?: number;
    photo: {
        url: string | null;
    };
}

export default Vue.extend({
    name: "UserPhoto",
    props: {
        user: {
            type: Object as PropType<UserPhoto>,
            required: false,
        },
        icon: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        userImage(): string | null {
            if (this.user && this.user.photo) {
                if (this.user.updatedAt) {
                    return this.$config.baseUrl + this.user.photo.url + "?ts=" + this.user.updatedAt;
                }
                return this.$config.baseUrl + this.user.photo.url;
            }
            return null;
        },
    },
});
