
import Vue, { PropType } from "vue";
import Logo from "@/views/shared/Logo.vue";
import {
    StationOrSensor,
    interpolatePartner,
    isCustomisationEnabled,
    getPartnerCustomizationWithDefault,
    PartnerCustomization,
} from "./partners";
import { DisplayProject, DisplayStation } from "@/store";

export default Vue.extend({
    name: "SidebarNav",
    components: {
        Logo,
        StationOrSensor,
    },
    props: {
        viewingProject: { type: Object, default: null },
        viewingStation: { type: Object, default: null },
        viewingProjects: { type: Boolean, default: false },
        viewingStations: { type: Boolean, default: false },
        isAuthenticated: { type: Boolean, required: true },
        stations: {
            type: Array as PropType<DisplayStation[]>,
            required: true,
        },
        projects: {
            type: Array as PropType<DisplayProject[]>,
            required: true,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        clipStations: {
            type: Boolean,
            default: false,
        },
    },
    mounted(): void {
        const desktopBreakpoint = 1040;
        const windowAny: any = window;
        const resizeObserver = new windowAny.ResizeObserver((entries) => {
            if (entries[0].contentRect.width < desktopBreakpoint) {
                if (!this.sidebar.narrow) {
                    this.sidebar.narrow = true;
                }
            }
        });

        resizeObserver.observe(document.querySelector("body"));
    },
    data(): {
        sidebar: {
            narrow: boolean;
        };
        narrowSidebarLogoIconClass: string;
        narrowSidebarLogoAlt: string;
    } {
        return {
            sidebar: {
                narrow: window.screen.availWidth <= 1040 || this.narrow,
            },
            narrowSidebarLogoIconClass: interpolatePartner("icon-logo-narrow-"),
            narrowSidebarLogoAlt: interpolatePartner("layout.logo.") + ".alt",
        };
    },
    watch: {
        $route(to, from): void {
            if (to.name === "viewProjectBigMap" || to.name === "root") {
                this.sidebar.narrow = true;
            }
        },
    },
    computed: {
        clippedStations(): DisplayStation[] {
            if (this.clipStations) {
                return this.stations.slice(0, 10);
            } else {
                return this.stations;
            }
        },
    },
    methods: {
        showStation(station: DisplayStation): void {
            this.$emit("show-station", station);
            this.closeMenuOnMobile();
        },
        closeMenuOnMobile(): void {
            if (window.screen.availWidth < 1040) {
                this.sidebar.narrow = true;
            }
        },
        toggleSidebar(): void {
            this.sidebar.narrow = !this.sidebar.narrow;
            this.$emit("sidebar-toggle");
        },
        openSidebar(): void {
            this.sidebar.narrow = false;
            this.$emit("sidebar-toggle");
        },
        closeSidebar(): void {
            this.sidebar.narrow = true;
            this.$emit("sidebar-toggle");
        },
        isPartnerCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
        partnerCustomization(): PartnerCustomization {
            return getPartnerCustomizationWithDefault();
        },
    },
});
