
import Vue from "vue";
import StandardLayout from "../StandardLayout.vue";
import CommonComponents from "@/views/shared";
import { PortalDeployStatus } from "@/api";

export default Vue.extend({
    name: "AdminMain",
    components: {
        StandardLayout,
        ...CommonComponents,
    },
    data(): {
        status: PortalDeployStatus | null;
    } {
        return {
            status: null,
        };
    },
    async mounted(): Promise<void> {
        await this.$services.api.getStatus().then((status) => {
            this.status = status;
        });
    },
});
