
import Vue from "vue";
import CommonComponents from "@/views/shared";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import FKApi from "@/api/api";
import Logo from "@/views/shared/Logo.vue";

export default Vue.extend({
    name: "CreateAccountView",
    components: {
        ...CommonComponents,
        Logo,
    },
    data(): {
        form: {
            name: string;
            email: string;
            password: string;
            passwordConfirmation: string;
            tncAccept: boolean;
        };
        available: boolean;
        creating: boolean;
        resending: boolean;
        created: { id: number } | null;
    } {
        return {
            form: {
                name: "",
                email: "",
                password: "",
                passwordConfirmation: "",
                tncAccept: false,
            },
            available: true,
            creating: true,
            resending: false,
            created: null,
        };
    },
    validations: {
        form: {
            name: {
                required,
            },
            email: {
                required,
                email,
                taken: function(this: any) {
                    return this.available;
                },
            },
            password: { required, min: minLength(10) },
            passwordConfirmation: { required, min: minLength(10), sameAsPassword: sameAs("password") },
            tncAccept: { checked: (value) => value === true },
        },
    },
    methods: {
        async save(): Promise<void> {
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) {
                return;
            }

            this.creating = true;

            await this.$services.api
                .register(this.form)
                .then((created) => {
                    this.created = created;
                })
                .catch((error) => {
                    if (error.status === 400) {
                        this.available = false;
                    } else {
                        return this.$seriousError(error);
                    }
                })
                .finally(() => {
                    this.creating = false;
                });
        },
        async resend(): Promise<void> {
            if (!this.created) throw new Error(`nothing to resend`);
            this.resending = true;
            await this.$services.api.resendCreateAccount(this.created.id).then(() => {
                this.resending = false;
            });
        },
    },
});
