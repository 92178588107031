
import Vue, { PropType } from "vue";
import {MapViewType} from '@/api';

export default Vue.extend({
    name: "MapViewTypeToggle",
    props: {
        routes: {
            required: true,
            type: Array as PropType<
                Array<{
                    name: string;
                    label: string;
                    params: object;
                    viewType: string;
                }>
            >,
        },
    },
    computed: {
        viewType(): MapViewType {
            if (this.$route.meta?.viewType) {
                return this.$route.meta.viewType;
            }
            return MapViewType.map;
        },
    },
});
