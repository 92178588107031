
import Vue from "@/store/strong-vue";
import HeaderBar from "./shared/HeaderBar.vue";
import SidebarNav from "./shared/SidebarNav.vue";
import Zoho from "./shared/Zoho.vue";
import { mapState, mapGetters } from "vuex";
import { GlobalState, DisplayStation } from "@/store";
import { getPartnerCustomizationWithDefault, isCustomisationEnabled } from "@/views/shared/partners";

export default Vue.extend({
    name: "StandardLayout",
    components: {
        HeaderBar,
        SidebarNav,
        Zoho,
    },
    props: {
        viewingProjects: {
            type: Boolean,
            default: false,
        },
        viewingProject: {
            type: Object,
            default: null,
        },
        viewingStations: {
            type: Boolean,
            default: false,
        },
        viewingStation: {
            type: Object,
            default: null,
        },
        defaultShowStation: {
            type: Boolean,
            default: true,
        },
        disableScrolling: {
            type: Boolean,
            default: false,
        },
        sidebarNarrow: {
            type: Boolean,
            default: getPartnerCustomizationWithDefault().sidebarNarrow,
        },
        clipStations: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy", mapped: "mapped" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            hasNoStations: (s: GlobalState) => s.stations.hasNoStations,
            stations: (s: GlobalState) => Object.values(s.stations.user.stations),
            userProjects: (s: GlobalState) => Object.values(s.stations.user.projects),
            anyStations: (s: GlobalState) => Object.values(s.stations.user.stations).length > 0,
        }),
    },
    beforeMount(): void {
        console.log("StandardLayout: beforeMount");
    },
    methods: {
        showStation(station: DisplayStation): Promise<any> {
            this.$emit("show-station", station.id);
            if (this.defaultShowStation) {
                // All parameters are strings.
                this.$router.push({ name: "mapStation", params: { id: String(station.id) } });
            }
            return Promise.resolve();
        },
        onSidebarToggle(): void {
            this.$emit("sidebar-toggle");
        },
        isPartnerCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
    },
});
