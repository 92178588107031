
import Vue from "vue";
import { interpolatePartner } from "./partners";

export default Vue.extend({
    name: "Logo",
    data(): {
        iconClass: string;
        altText: string;
    } {
        return {
            iconClass: interpolatePartner("icon-logo-"),
            altText: interpolatePartner("layout.logo.") + ".alt",
        };
    },
});
