
import Vue from "vue";
import StandardLayout from "@/views/StandardLayout.vue";
import DoubleHeader from "@/views/shared/DoubleHeader.vue";
import StationPhoto from "@/views/shared/StationPhoto.vue";
import LatestStationReadings from "@/views/shared/LatestStationReadings.vue";
import AuthenticatedPhoto from "@/views/shared/AuthenticatedPhoto.vue";
import {
    ActionTypes,
    AuthenticationRequiredError,
    BoundingRectangle,
    DisplayModule,
    DisplayStation,
    GlobalState,
    MappedStations,
    ProjectAttribute,
    ProjectModule,
    VisibleReadings,
} from "@/store";
import * as utils from "@/utilities";
import { NoteMedia, PortalNoteMedia, PortalStationNotes } from "@/views/notes/model";
import NotesForm from "@/views/notes/NotesForm.vue";
import StationsMap from "@/views/shared/StationsMap.vue";
import ProjectAttributes from "@/views/projects/ProjectAttributes.vue";
import StationBattery from "@/views/station/StationBattery.vue";
import { getPartnerCustomizationWithDefault, isCustomisationEnabled, PartnerCustomization } from "@/views/shared/partners";
import UserPhoto from "@/views/shared/UserPhoto.vue";
import { Project } from "@/api";
import { mapState } from "vuex";
import { SensorDataQuerier } from "@/views/shared/sensor_data_querier";
import TinyChart from "@/views/viz/TinyChart.vue";
import { BookmarkFactory, serializeBookmark } from "@/views/viz/viz";
import { ExploreContext } from "@/views/viz/common";
import FieldNotes from "@/views/fieldNotes/FieldNotes.vue";
import { confirmLeaveWithDirtyCheck } from "@/store/modules/dirty";
import { SnackbarStyle } from "@/store/modules/snackbar";

export default Vue.extend({
    name: "StationView",
    components: {
        StationBattery,
        StandardLayout,
        DoubleHeader,
        StationPhoto,
        LatestStationReadings,
        StationsMap,
        NotesForm,
        AuthenticatedPhoto,
        ProjectAttributes,
        TinyChart,
        UserPhoto,
        FieldNotes,
    },
    data(): {
        selectedModule: DisplayModule | null;
        isMobileView: boolean;
        loading: boolean;
        sensorDataQuerier: SensorDataQuerier;
        editModuleIndex: number | null;
        editingDescription: boolean;
        form: {
            description: string | null;
        };
        editedModule: DisplayModule | null;
    } {
        return {
            selectedModule: null,
            isMobileView: window.screen.availWidth <= 500,
            loading: true,
            editedModule: null,
            editModuleIndex: null,
            editingDescription: false,
            form: {
                description: "",
            },
            sensorDataQuerier: new SensorDataQuerier(this.$services.api),
        };
    },
    watch: {
        station() {
            this.loading = false;
            this.form.description = this.station.description;
            if (!this.selectedModule) {
                this.selectedModule = this.station.modules[0];
            }
        },
    },
    computed: {
        ...mapState({
            userStations: (s: GlobalState) => Object.values(s.stations.user.stations),
        }),
        visibleReadings(): VisibleReadings {
            return VisibleReadings.Current;
        },
        projectId(): string {
            return this.$route.params.projectId;
        },
        station(): DisplayStation {
            return this.$state.stations.stations[this.$route.params.stationId];
        },
        notes(): PortalStationNotes[] {
            return this.$state.notes.notes;
        },
        media(): PortalNoteMedia[] {
            return this.$state.notes.media;
        },
        photos(): NoteMedia[] | null {
            if (this.$state.notes.media) {
                return NoteMedia.onlyPhotos(this.$state.notes.media);
            }
            return null;
        },
        attributes(): ProjectAttribute[] {
            const station = this.$state.stations.stations[this.$route.params.stationId];
            return station.attributes;
        },
        deployedDate(): string | null {
            if (this.station) {
                const deploymentDate = this.partnerCustomization().getStationDeploymentDate(this.station);
                if (deploymentDate) {
                    return this.$tc("station.deployed") + " " + deploymentDate;
                }
                return this.$tc("station.readyToDeploy");
            }
            return null;
        },
        mapBounds(): BoundingRectangle {
            return MappedStations.defaultBounds();
        },
        mapped(): MappedStations | null {
            if (!this.station.id) {
                return null;
            }

            const mapped = MappedStations.make([this.station]);

            return mapped.focusOn(this.station.id);
        },
        showMap(): boolean {
            if (this.mapped && this.mapped.features.length > 0) {
                return true;
            }

            return false;
        },
        stationProjects(): Project[] {
            return this.$store.getters.stationProjects;
        },
        isPartnerCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
        isModuleNameEditable(): boolean {
            return !this.isPartnerCustomisationEnabled && !this.station.readOnly;
        },
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        confirmLeaveWithDirtyCheck(() => {
            next();
        }, this);
    },
    beforeMount(): Promise<any> {
        const stationId = this.$route.params.stationId;

        this.$store.dispatch(ActionTypes.NEED_NOTES, { id: stationId });
        this.$store.dispatch(ActionTypes.NEED_PROJECTS_FOR_STATION, { id: this.$route.params.stationId });

        return this.$store.dispatch(ActionTypes.NEED_STATION, { id: stationId }).catch((e) => {
            if (AuthenticationRequiredError.isInstance(e)) {
                return this.$router.push({
                    name: "login",
                    params: { errorMessage: this.$t("login.privateStation").toString() },
                    query: { after: this.$route.path },
                });
            }
        });
    },
    methods: {
        getBatteryIcon(): string {
            if (!this.station || !this.station.battery) {
                return "";
            }
            return this.$loadAsset(utils.getBatteryIcon(this.station.battery));
        },
        getModuleImg(module: DisplayModule): string {
            return this.$loadAsset(utils.getModuleImg(module));
        },
        getModuleName(module: DisplayModule): string {
            return module.label || this.$tc(module.name.replace("modules.", "fk."));
        },
        getModuleKey(module: DisplayModule): string {
            return module.name.replace("modules.", "fk.");
        },
        partnerCustomization(): PartnerCustomization {
            return getPartnerCustomizationWithDefault();
        },
        isCustomizationEnabled(): boolean {
            return isCustomisationEnabled();
        },
        onClickExplore(): void {
            const exploreContext = new ExploreContext();
            const bm = BookmarkFactory.forStation(this.station.id, exploreContext);
            const url = this.$router.resolve({
                name: "exploreBookmark",
                query: { bookmark: serializeBookmark(bm) },
            }).href;
            window.open(url, "_blank");
        },
        async saveStationDescription(): Promise<void> {
            const payload = { id: this.station.id, name: this.station.name, ...this.form };

            this.$store
                .dispatch(ActionTypes.UPDATE_STATION, payload)
                .then(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("station.descriptionUpdateSuccess"),
                        type: SnackbarStyle.success,
                    });
                })
                .catch(() => {
                    this.form.description = this.station.description;
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                })
                .finally(() => {
                  this.$store.dispatch(ActionTypes.UPDATE_STATION, payload);
                  this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "stationDescription");
                    this.editingDescription = false;
                });
        },
        onEditModuleNameClick(module: DisplayModule): void {
            this.editedModule = JSON.parse(JSON.stringify(module));
            if (this.editedModule) {
                this.editedModule.label = this.getModuleName(module);
            }
            this.$store.dispatch(ActionTypes.NEW_DIRTY_FIELD, "editModuleName");
        },
        saveModuleName(): void {
            if (!this.editedModule) {
                return;
            }
            const payload = { stationId: this.station.id, moduleId: this.editedModule.id, label: this.editedModule.label };
            this.$store
                .dispatch(ActionTypes.UPDATE_STATION_MODULE, payload)
                .then(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("station.moduleNameUpdateSuccess"),
                        type: SnackbarStyle.success,
                    });
                })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                })
                .finally(() => {
                    this.editedModule = null;
                    this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editModuleName");
                });
        },
        selectModule(module: DisplayModule) {
            this.selectedModule = module;
        },
        navigateToPhotos(): void {
            this.$router.push({
                name: this.projectId ? "viewProjectStationPhotos" : "viewStationPhotos",
                params: { projectId: this.projectId, stationId: String(this.station.id) },
            });
        },

        onModuleClick(moduleId: number) {
            const tinyChartComp = this.$refs["tinyChart-" + moduleId];
            if (tinyChartComp && tinyChartComp[0]) {
                const vizData = tinyChartComp[0].vizData;
                if (vizData) {
                    const bm = BookmarkFactory.forSensor(this.station.id, vizData.vizSensor, vizData.timeRange);
                    const url = this.$router.resolve({
                        name: "exploreBookmark",
                        query: { bookmark: serializeBookmark(bm) },
                    }).href;
                    window.open(url, "_blank");
                }
            }
        },
        onStationDescriptionInput() {
            const el = this.$refs["stationDescription"] as HTMLElement;

            if (!el) {
                throw new Error("Can not find stationDescription ref");
            }

            el.style.height = "";
            el.style.height = el.scrollHeight + "px";
            this.$store.dispatch(ActionTypes.NEW_DIRTY_FIELD, "stationDescription");
        },
    },
});
