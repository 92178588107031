
import Vue from "vue";

export default Vue.extend({
    name: "SnackBar",
    data: () => {
        return {};
    },
    computed: {
        isVisible() {
            return this.$state.snackbar.visible;
        },
        message() {
            return this.$state.snackbar.message;
        },
        style() {
            return this.$state.snackbar.style;
        },
    },
});
