
import Vue from "vue";
import StandardLayout from "@/views/StandardLayout.vue";
import DoubleHeader from "@/views/shared/DoubleHeader.vue";
import AuthenticatedPhoto from "@/views/shared/AuthenticatedPhoto.vue";
import { AddedPhoto, NoteMedia, PortalNoteMedia } from "@/views/notes/model";
import { ActionTypes } from "@/store";
import ListItemOptions, { ListItemOption } from "@/views/shared/ListItemOptions.vue";
import { SnackbarStyle } from "@/store/modules/snackbar";

export default Vue.extend({
    name: "StationPhotosView",
    components: {
        StandardLayout,
        DoubleHeader,
        AuthenticatedPhoto,
        ListItemOptions,
    },
    computed: {
        projectId(): number {
            return parseInt(this.$route.params.projectId, 10);
        },
        stationId(): number {
            return parseInt(this.$route.params.stationId, 10);
        },
        photos(): NoteMedia[] {
            if (this.$state.notes.media) {
                return NoteMedia.onlyPhotos(this.$state.notes.media);
            }
            return [];
        },
        media(): PortalNoteMedia[] {
            return this.$state.notes.media;
        },
        readOnly(): boolean {
            return this.$state.notes.readOnly;
        },
    },
    data: (): {
        photoOptions: ListItemOption[];
        loadingPhotoId: number | null;
        gallery: { src: string; photo: NoteMedia }[];
    } => {
        return {
            photoOptions: [],
            loadingPhotoId: null,
            gallery: [],
        };
    },
    watch: {
        photos() {
            this.initGallery();
        },
    },
    methods: {
        async onPhotoOptionClick(event: string, photo: PortalNoteMedia) {
            if (event === "delete-photo") {
                await this.$confirm({
                    message: this.$tc("confirmDeletePhoto"),
                    button: {
                        no: this.$tc("cancel"),
                        yes: this.$tc("delete"),
                    },
                    callback: async (confirm) => {
                        if (confirm) {
                            this.loadingPhotoId = photo.id;
                            this.$services.api
                                .deleteMedia(photo.id)
                                .then(async () => {
                                    await this.$store.dispatch(ActionTypes.NEED_NOTES, { id: this.$route.params.stationId });
                                    await this.$store.dispatch(ActionTypes.CLEAR_STATION, this.$route.params.stationId);
                                })
                                .finally(() => {
                                    this.onFinishedPhotoAction(this.$tc("successDeletePhoto"), SnackbarStyle.success);
                                })
                                .catch(() => {
                                    this.onFinishedPhotoAction(this.$tc("somethingWentWrong"), SnackbarStyle.fail);
                                });
                        }
                    },
                });
            }
            if (event === "set-as-station-image") {
                this.loadingPhotoId = photo.id;
                this.$services.api
                    .setStationImage(this.stationId, photo.id)
                    .then(() => {
                        this.onFinishedPhotoAction(this.$tc("successSetAsStationPhoto"), SnackbarStyle.success);
                        this.$store.dispatch(ActionTypes.NEED_NOTES, { id: this.$route.params.stationId });
                    })
                    .catch(() => {
                        this.onFinishedPhotoAction(this.$tc("somethingWentWrong"), SnackbarStyle.fail);
                    });
            }
        },
        onFinishedPhotoAction(message: string, type: SnackbarStyle): void {
            this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, { message, type });
            this.loadingPhotoId = null;
        },
        upload(this: any, ev) {
            const image = ev.target.files[0];

            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev?.target?.result) {
                    const photo = new AddedPhoto(image.type, image, ev.target.result);
                    this.$services.api
                        .uploadStationMedia(this.stationId, photo.key, photo.file)
                        .then(() => {
                            this.$store.dispatch(ActionTypes.NEED_NOTES, { id: this.stationId });
                            this.onFinishedPhotoAction(this.$tc("successAddedPhoto"), SnackbarStyle.success);
                            return [];
                        })
                        .catch(() => {
                            this.onFinishedPhotoAction(this.$tc("somethingWentWrong"), SnackbarStyle.fail);
                        });
                }
            };

            reader.readAsDataURL(image);
        },
        initGallery(): void {
            this.gallery = [];
            this.photos.forEach((photo) => {
                this.$services.api.loadMedia(photo["url"]).then((src) => {
                    this.gallery.push({
                        src: src,
                        photo: photo,
                    });
                });
            });
        },
        togglePageScroll(): void {
            document.body.classList.toggle("disable-scrolling");
        },
    },
    mounted() {
        this.photoOptions = [
            {
                label: this.$tc("station.btn.setAsStationImage"),
                event: "set-as-station-image",
                icon: "icon-home",
            },
            {
                label: this.$tc("station.btn.deletePhoto"),
                event: "delete-photo",
                icon: "icon-trash",
            },
        ];
    },
    beforeMount(): Promise<any> {
        return this.$store.dispatch(ActionTypes.NEED_NOTES, { id: this.stationId });
    },
});
