
import _ from "lodash";
import Vue, { PropType } from "vue";
import { Module } from "@/api";
import { SensorDataQuerier } from "./sensor_data_querier";

export enum TrendType {
    Downward,
    Steady,
    Upward,
}

export class SensorReading {
    constructor(
        public readonly labelKey: string,
        public readonly classes: string,
        public readonly order: number,
        public readonly unitOfMeasure: string,
        public readonly reading: number,
        public readonly trend: TrendType,
        public readonly internal: boolean,
        public readonly sensorModule: Module
    ) {}
}

export default Vue.extend({
    name: "LatestStationReadings",
    components: {},
    props: {
        id: {
            type: Number,
            required: true,
        },
        moduleKey: {
            type: String,
            required: false,
        },
        sensorDataQuerier: {
            type: Object as PropType<SensorDataQuerier>,
            required: false,
        },
    },
    data(): {
        loading: boolean;
        sensors: SensorReading[];
        querier: SensorDataQuerier;
    } {
        return {
            loading: true,
            sensors: [],
            querier: this.sensorDataQuerier || new SensorDataQuerier(this.$services.api),
        };
    },
    watch: {
        async id(): Promise<void> {
            await this.refresh();
        },
        async moduleKey(): Promise<void> {
            await this.refresh();
        },
    },
    async beforeMount(): Promise<void> {
        await this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;

            return this.querier
                .queryTinyChartData(this.id)
                .then(([data, quickSensors, meta]) => {
                    const sensorsToModule = _.fromPairs(
                        _.flatten(
                            meta.modules.map((module) => {
                                return module.sensors.map((sensor) => [sensor.fullKey, module]);
                            })
                        )
                    );

                    const sensorsByKey = _(meta.modules)
                        .map((m) => m.sensors)
                        .flatten()
                        .keyBy((s) => s.fullKey)
                        .value();

                    const idsToKey = _.mapValues(
                        _.keyBy(meta.sensors, (k) => k.id),
                        (v) => v.key
                    );

                    const idsToValue = _.mapValues(
                        _.keyBy(data.data, (r) => r.sensorId),
                        (r) => r
                    );

                    const keysToValue = _(idsToValue)
                        .map((value, id) => [idsToKey[id], value])
                        .fromPairs()
                        .value();

                    const readings = _(keysToValue)
                        .map((reading, key) => {
                            const sensor = sensorsByKey[key];
                            if (!sensor) throw new Error("no sensor meta");

                            const value = reading.value || reading[sensor.aggregationFunction];

                            const classes = [key.replaceAll(".", "-")];
                            if (sensor.unitOfMeasure == "°") {
                                classes.push("degrees");
                            }
                            const sensorModule = sensorsToModule[key];
                            if (!sensorModule) throw new Error("no sensor module");

                            return new SensorReading(
                                key,
                                classes.join(" "),
                                sensor.order,
                                sensor.unitOfMeasure,
                                value,
                                TrendType.Steady,
                                sensor.internal,
                                sensorModule
                            );
                        })
                        .value();

                    return _.orderBy(
                        readings.filter((sr) => !sr.internal),
                        (sr) => sr.order
                    );
                })
                .then((sensors) => {
                    if (this.moduleKey) {
                        this.sensors = sensors.filter((sensor) => sensor.sensorModule.key === this.moduleKey);
                    } else {
                        this.sensors = sensors;
                    }
                    this.loading = false;
                    this.$emit("layoutChange");

                    return this.sensors;
                });
        },
    },
});
