
import _ from "lodash";
import Vue, { PropType } from "vue";
import CommonComponents from "@/views/shared";

import { required, email, minLength, requiredIf } from "vuelidate/lib/validators";

import { LoginPayload } from "@/api/api";

export default Vue.extend({
    name: "LoginForm",
    components: {
        ...CommonComponents,
    },
    props: {
        spoofing: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        forwardAfterQuery: {
            type: Object as PropType<{ after?: string }>,
            default: () => {
                return { after: null };
            },
        },
        heading: {
            type: String,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        showCreateAccount: {
            type: Boolean,
            default: true,
        },
        message: {
            type: String,
            default: null,
        },
    },
    data(): {
        form: {
            spoofEmail: string;
            email: string;
            password: string;
        };
    } {
        return {
            form: {
                spoofEmail: "",
                email: "",
                password: "",
            },
        };
    },
    validations() {
        return {
            form: {
                spoofEmail: {
                    // eslint-disable-next-line
                    required: requiredIf(function (this: any) {
                        return this.spoofing;
                    }),
                    email,
                },
                email: {
                    required,
                    email,
                },
                password: { required, min: minLength(10) },
            },
        };
    },
    methods: {
        createPayload(): LoginPayload {
            if (this.spoofing) {
                return new LoginPayload(this.form.spoofEmail, this.form.email + " " + this.form.password);
            }
            return new LoginPayload(this.form.email, this.form.password);
        },
        async save(): Promise<void> {
            this.$v.form.$touch();
            if (this.busy || this.$v.form.$pending || this.$v.form.$error) {
                return;
            }
            const payload = this.createPayload();
            this.$emit("login", payload);
        },
    },
});
