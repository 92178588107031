
import Vue from "vue";
import CommonComponents from "@/views/shared";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Logo from "@/views/shared/Logo.vue";

export default Vue.extend({
    name: "RecoverAccountView",
    components: {
        ...CommonComponents,
        Logo,
    },
    data(): {
        form: {
            email: string;
        };
        resending: boolean;
        attempted: boolean;
        busy: boolean;
    } {
        return {
            form: {
                email: "",
            },
            resending: false,
            attempted: false,
            busy: false,
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
        },
    },
    methods: {
        async save(): Promise<void> {
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) {
                return;
            }
            this.busy = true;
            await this.$services.api
                .sendResetPasswordEmail(this.form.email)
                .then(() => (this.attempted = true))
                .finally(() => (this.busy = true));
        },
        async resend(): Promise<void> {
            this.resending = true;
            await this.save().finally(() => (this.resending = false));
        },
    },
});
