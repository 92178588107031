
import Vue from "vue";
import CommonComponents from "@/views/shared";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Logo from "@/views/shared/Logo.vue";

export default Vue.extend({
    name: "ResetPasswordView",
    components: {
        ...CommonComponents,
        Logo,
    },
    data: () => {
        return {
            form: {
                password: "",
                passwordConfirmation: "",
            },
            busy: false,
            success: false,
            failed: false,
        };
    },
    validations: {
        form: {
            password: { required, min: minLength(10) },
            passwordConfirmation: { required, min: minLength(10), sameAsPassword: sameAs("password") },
        },
    },
    methods: {
        save(this: any) {
            console.log("save");
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) {
                return;
            }

            this.success = false;
            this.failed = false;
            this.busy = true;
            const payload = {
                token: this.$route.query.token,
                password: this.form.password,
            };
            return this.$services.api
                .resetPassword(payload)
                .then(() => {
                    this.success = true;
                })
                .catch(() => {
                    this.failed = true;
                })
                .finally(() => {
                    this.busy = false;
                });
        },
    },
});
