
import Vue from "vue";
import StandardLayout from "../StandardLayout.vue";
import ProjectForm from "./ProjectForm.vue";

import { Project } from "@/api/api";
import * as ActionTypes from "@/store/actions";

export default Vue.extend({
    name: "ProjectEditView",
    components: {
        StandardLayout,
        ProjectForm,
    },
    props: {
        id: {
            type: Number,
        },
    },
    data(): {
        user: unknown;
        activeProject: Project | null;
        loading: boolean;
        projectDirty: boolean;
    } {
        return {
            user: {},
            activeProject: null,
            loading: true,
            projectDirty: false,
        };
    },
    async mounted(): Promise<void> {
        if (this.id) {
            await this.getProject(this.id);
        } else {
            this.loading = false;
        }
    },
    beforeMount(): Promise<void> | void {
        if (this.id) {
            return this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.id });
        }
    },
    beforeRouteLeave(to: never, from: never, next: any) {
        if (this.projectDirty) {
            this.$confirm({
                message: this.$tc("project.form.confirmLeavePopupMessage"),
                button: {
                    no: this.$tc("no"),
                    yes: this.$tc("yes"),
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.projectDirty = false;
                        next();
                    }
                },
            });
        } else {
            next();
        }
    },
    methods: {
        goBack(): void {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
        },
        async getProject(projectId: number): Promise<void> {
            this.loading = true;
            await this.$services.api
                .getProject(projectId)
                .then((project) => {
                    this.activeProject = project;
                    this.loading = false;
                })
                .catch(() => {
                    return this.$router.push({ name: "projects" });
                });
        },
        onProjectUpdate(): void {
            this.loading = true;
            this.projectDirty = false;
        },
    },
});
