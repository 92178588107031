import { render, staticRenderFns } from "./Playground.vue?vue&type=template&id=5901494d&scoped=true&"
import script from "./Playground.vue?vue&type=script&lang=ts&"
export * from "./Playground.vue?vue&type=script&lang=ts&"
import style0 from "./Playground.vue?vue&type=style&index=0&id=5901494d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5901494d",
  null
  
)

export default component.exports