
import _ from "lodash";

import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";

import CommonComponents from "@/views/shared";
import StationBattery from "@/views/station/StationBattery.vue";
import StationSummaryContent from "./StationSummaryContent.vue";

import { ModuleSensorMeta, SensorDataQuerier, SensorMeta } from "@/views/shared/sensor_data_querier";
import { VisibleReadings, DecoratedReading } from "@/store";

import { getBatteryIcon } from "@/utilities";
import { BookmarkFactory, ExploreContext, serializeBookmark } from "@/views/viz/viz";
import { getPartnerCustomizationWithDefault, interpolatePartner, isCustomisationEnabled } from "./partners";
import { StationStatus } from "@/api";
import { CupertinoPane } from "cupertino-pane";

export default Vue.extend({
    name: "StationHoverSummary",
    components: {
        ...CommonComponents,
        StationSummaryContent,
        StationBattery,
    },
    props: {
        station: {
            type: Object,
            required: true,
        },
        explore: {
            type: Boolean,
            default: true,
        },
        exploreContext: {
            type: Object as PropType<ExploreContext>,
            default: () => {
                return new ExploreContext();
            },
        },
        sensorDataQuerier: {
            type: Object as PropType<SensorDataQuerier>,
            required: false,
        },
        visibleReadings: {
            type: Number as PropType<VisibleReadings>,
            default: VisibleReadings.Current,
        },
        hasCupertinoPane: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        integer: (value) => {
            if (!value) return "";
            return Math.round(value);
        },
    },
    watch: {
        station(this: any) {
            if (this.cupertinoPane) {
                this.cupertinoPane.present({ animate: true });
            }
        },
    },
    data(): {
        viewingSummary: boolean;
        sensorMeta: SensorMeta | null;
        StationStatus: any;
        isMobileView: boolean;
        cupertinoPane: CupertinoPane | null;
    } {
        return {
            viewingSummary: true,
            sensorMeta: null,
            StationStatus: StationStatus,
            isMobileView: window.screen.availWidth < 500,
            cupertinoPane: null,
        };
    },
    async mounted() {
        if (this.hasCupertinoPane && this.isMobileView) {
            this.initCupertinoPane();
        }
        this.sensorMeta = await this.sensorDataQuerier.querySensorMeta();
    },
    destroyed() {
        this.destroyCupertinoPane();
    },
    computed: {
        ...mapGetters({ projectsById: "projectsById" }),
        visibleSensor(): ModuleSensorMeta | null {
            const primarySensor = this.station.primarySensor;
            if (this.sensorMeta && primarySensor) {
                return this.sensorMeta.findSensorByKey(primarySensor.fullKey);
            }
            return null;
        },
        hasData(): boolean {
            return this.station.hasData;
        },
        decoratedReading(): DecoratedReading | null {
            const readings = this.station.getDecoratedReadings(this.visibleReadings);
            if (readings && readings.length > 0) {
                return readings[0];
            }
            return null;
        },
        visibleReadingValue(): number | null {
            const reading: DecoratedReading | null = this.decoratedReading;
            if (reading) {
                return reading.value;
            }
            return null;
        },
        latestPrimaryLevel(): any {
            const reading: DecoratedReading | null = this.decoratedReading;
            if (reading) {
                return reading?.thresholdLabel;
            }
            return null;
        },
        latestPrimaryColor(): string {
            const reading: DecoratedReading | null = this.decoratedReading;
            if (reading === null) {
                return getPartnerCustomizationWithDefault().latestPrimaryNoDataColor;
            }
            if (reading) {
                return reading?.color;
            }
            return "#00CCFF";
        },
    },
    methods: {
        viewSummary() {
            this.viewingSummary = true;
        },
        onClickExplore() {
            const bm = BookmarkFactory.forStation(this.station.id, this.exploreContext);
            return this.$router.push({
                name: "exploreBookmark",
                query: { bookmark: serializeBookmark(bm) },
            });
        },
        getBatteryIcon() {
            return this.$loadAsset(getBatteryIcon(this.station.battery));
        },
        wantCloseSummary() {
            this.$emit("close");
        },
        openStationPageTab() {
            const routeData = this.$router.resolve({ name: "viewStationFromMap", params: { stationId: this.station.id } });
            window.open(routeData.href, "_blank");
        },
        interpolatePartner(baseString) {
            return interpolatePartner(baseString);
        },
        isPartnerCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
        async initCupertinoPane(): Promise<void> {
            const paneContentEl = this.$refs["paneContent"] as HTMLDivElement;
            const generalRowEl = (this.$refs["summaryContent"] as Vue).$refs["summaryGeneralRow"] as HTMLDivElement;
            this.cupertinoPane = new CupertinoPane(".js-cupertinoPane", {
                parentElement: "body",
                breaks: {
                    top: { enabled: true, height: paneContentEl.scrollHeight, bounce: true },
                    // add padding top of container and margin of general row
                    middle: { enabled: true, height: generalRowEl.scrollHeight + 25 + 10, bounce: true },
                    bottom: { enabled: true, height: 0 },
                },
                bottomClose: true,
                buttonDestroy: false,
            });
            this.cupertinoPane.present({ animate: true });
        },
        destroyCupertinoPane(): void {
            if (this.cupertinoPane) {
                this.cupertinoPane.destroy();
            }
        },
    },
});
