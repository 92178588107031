
import Vue, { PropType } from "vue";
import { tryParseTags } from "@/utilities";

export default Vue.extend({
    name: "DisplayProjectTags",
    props: {
        tags: {
            type: String,
            required: true,
        },
    },
    computed: {
        parsed() {
            return tryParseTags(this.tags);
        },
    },
});
