
import Vue from "vue";
import NewPhoto from "../../assets/profile-image-placeholder.svg";

export interface Image {
    url: string;
}

export interface UploadedImage {
    type: string;
    image: unknown;
    file: unknown;
}

export default Vue.extend({
    name: "ImageUploader",
    props: {
        image: {
            type: Object,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        allowPreview: {
            type: Boolean,
            default: true,
        },
    },
    data: () => {
        return {
            imageType: null,
            preview: null,
            acceptedTypes: ["jpg", "jpeg", "png", "gif"],
            photo: null,
        };
    },
    computed: {
        placeholderImage() {
            return this.placeholder || NewPhoto;
        },
    },
    mounted(this: any) {
        if (this.image && this.image.url) {
            return this.$services.api.loadMedia(this.image.url, { size: 800 }).then((photo) => {
                this.photo = photo;
            });
        }
    },
    methods: {
        acceptable(this: any, files: { type: string }[]): boolean {
            if (files.length != 1) {
                return false;
            }
            return this.acceptedTypes.filter((t) => files[0].type.indexOf(t) > -1).length > 0;
        },
        upload(this: any, ev) {
            this.preview = null;

            if (!this.acceptable(ev.target.files)) {
                return;
            }

            const image = ev.target.files[0];
            this.imageType = ev.target.files[0].type;

            if (this.allowPreview) {
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (ev) => {
                    const r = ev?.target?.result;
                    if (r) {
                        this.preview = r;
                    }
                };
            }

            const uploaded: UploadedImage = {
                type: this.imageType,
                image: image, // TODO Remove
                file: image,
            };

            this.$emit("change", uploaded);
        },
        imageInputClick() {
            (this.$refs.imageInput as HTMLElement).click();
        },
    },
});
