
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import StandardLayout from "../StandardLayout.vue";
import ProjectThumbnails from "./ProjectThumbnails.vue";
import { PendingInvites } from "@/api";
import { StationsState } from "@/store/modules/stations";

export default Vue.extend({
    name: "ProjectsView",
    components: {
        StandardLayout,
        ProjectThumbnails,
    },
    data(): {
        invites: PendingInvites | null;
    }
     {
        return {
            invites: null,
        };
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated" }),
        ...mapState({
            userProjects: (s: { stations: StationsState }) => Object.values(s.stations.user.projects),
            publicProjects: (s: { stations: StationsState }) => Object.values(s.stations.community.projects),
        }),
    },
    async mounted(): Promise<void> {
        if (this.isAuthenticated) {
            this.invites = await this.$services.api.getInvitesByUser();
        }
    },
    methods: {
        goBack() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push("/");
            }
        },
        addProject() {
            this.$router.push({ name: "addProject" });
        },
    },
});
