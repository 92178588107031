
import Vue from "vue";
import UserPhoto from "@/views/shared/UserPhoto.vue";
import SelectField from "@/views/shared/SelectField.vue";
import { required, email } from "vuelidate/lib/validators";
import * as ActionTypes from "@/store/actions";
import { mapState } from "vuex";
import { CurrentUser, GlobalState } from "@/store";

export default Vue.extend({
    name: "TeamManager",
    components: {
        UserPhoto,
        SelectField,
    },
    props: {
        displayProject: {
            type: Object,
            required: true,
        },
    },
    data: (): {
        form: {
            inviteEmail: string;
            inviteDuplicate: boolean;
            selectedRole: number;
        };
        roleOptions: {
            value: number;
            label: string;
            disabled?: boolean;
        }[];
        edited: {
            email: string | null;
            role: number | null;
        };
    } => {
        return {
            form: {
                inviteEmail: "",
                inviteDuplicate: false,
                selectedRole: -1,
            },
            roleOptions: [
                {
                    value: -1,
                    label: "Select Role",
                    disabled: true,
                },
                {
                    value: 0,
                    label: "Member",
                },
                {
                    value: 1,
                    label: "Administrator",
                },
            ],
            edited: {
                email: null,
                role: null,
            },
        };
    },
    validations: {
        form: {
            inviteEmail: {
                required,
                email,
            },
        },
    },
    computed: {
        ...mapState({
            user: (s: GlobalState) => s.user.user,
        }),
        users(): CurrentUser[] {
            const loggedInUser = this.displayProject.users.filter((user) => user.user.id === this.user?.id)[0];
            const otherUsers = this.displayProject.users.filter((user) => user.user.id !== this.user?.id);
            return [loggedInUser, ...otherUsers];
        },
    },
    methods: {
        checkEmail(this: any) {
            this.$v.form.$touch();
            return !(this.$v.form.$pending || this.$v.form.$error);
        },
        onEditMemberRole(role: number, email: string): void {
            this.edited = {
                role,
                email,
            };
        },
        submitEditMemberRole(): Promise<any> {
            const payload = {
                projectId: this.displayProject.id,
                role: this.edited.role,
                email: this.edited.email,
            };
            return this.$store.dispatch(ActionTypes.PROJECT_EDIT_ROLE, payload).then(() => {
                this.edited.email = null;
                this.edited.role = null;
            });
        },
        sendInvite(this: any) {
            if (this.checkEmail()) {
                if (this.form.selectedRole === -1) {
                    this.form.selectedRole = 0;
                }
                const payload = {
                    projectId: this.displayProject.id,
                    email: this.form.inviteEmail,
                    role: this.form.selectedRole,
                };
                return this.$store
                    .dispatch(ActionTypes.PROJECT_INVITE, payload)
                    .then(() => {
                        this.$v.form.$reset();
                        this.form.inviteEmail = "";
                        this.form.inviteDuplicate = false;
                    })
                    .catch(() => {
                        // TODO: Move this to vuelidate.
                        this.form.inviteDuplicate = true;
                    });
            }
        },
        removeUser(this: any, projectUser) {
            return this.$confirm({
                message: this.$t("project.teamManager.confirmRemove"),
                button: {
                    no: this.$t("no"),
                    yes: this.$t("yes"),
                },
                callback: (confirm) => {
                    if (confirm) {
                        const payload = {
                            projectId: this.displayProject.id,
                            email: projectUser.user.email,
                        };
                        return this.$store.dispatch(ActionTypes.PROJECT_REMOVE, payload).then(() => {
                            this.$v.form.$reset();
                            this.form.inviteEmail = "";
                        });
                    }
                },
            });
        },
    },
});
