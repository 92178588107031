
import _ from "lodash";
import Vue from "vue";
import Promise from "bluebird";
import CommonComponents from "@/views/shared";
import StandardLayout from "../StandardLayout.vue";
import StationTabs from "./StationTabs.vue";
import NotesForm from "./NotesForm.vue";

import { mapState, mapGetters } from "vuex";
import * as ActionTypes from "@/store/actions";
import { GlobalState } from "@/store/modules/global";

import { serializePromiseChain } from "@/utilities";

import { PortalStationNotesReply, Notes, mergeNotes } from "./model";
import { DisplayStation, DisplayProject } from "@/store";
import { confirmLeaveWithDirtyCheck } from "@/store/modules/dirty";

export default Vue.extend({
    name: "NotesView",
    components: {
        ...CommonComponents,
        StandardLayout,
        NotesForm,
    },
    props: {
        projectId: {
            type: Number,
            required: false,
        },
        stationId: {
            type: Number,
            required: false,
        },
        selected: {
            type: Object,
            required: false,
        },
    },
    data(): {
        notes: { [stationId: number]: PortalStationNotesReply };
        loading: boolean;
        success: boolean;
        failed: boolean;
        mobileView: boolean;
        isStationSelected: boolean;
    } {
        return {
            notes: {},
            loading: false,
            success: false,
            failed: false,
            mobileView: window.screen.availWidth < 1040,
            isStationSelected: true,
        };
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            userProjects: (s: GlobalState) => s.stations.user.projects,
        }),
        hasStations(): boolean {
            return this.visibleStations.length > 0;
        },
        project(): DisplayProject | null {
            if (this.projectId) {
                return this.$getters.projectsById[this.projectId];
            }
            return null;
        },
        stations(): DisplayStation[] {
            return this.$getters.projectsById[this.projectId].stations;
        },
        visibleStations(): DisplayStation[] {
            if (this.projectId) {
                const project = this.$getters.projectsById[this.projectId];
                if (project) {
                    return project.stations;
                }
                return [];
            }
            return this.$store.state.stations.user.stations;
        },
        selectedStation(): DisplayStation | null {
            if (this.stationId) {
                const station = this.$getters.stationsById[this.stationId];
                if (station) {
                    return station;
                }
            }
            return null;
        },
        selectedNotes(): PortalStationNotesReply | null {
            if (this.stationId && this.notes) {
                return this.notes[this.stationId];
            }
            return null;
        },
    },
    watch: {
        async stationId(): Promise<void> {
            await this.loadNotes(this.stationId);
        },
    },
    async mounted(): Promise<void> {
        const desktopBreakpoint = 768;
        const windowAny: any = window;
        const resizeObserver = new windowAny.ResizeObserver((entries) => {
            const windowWidth = entries[0].contentRect.width;

            if (this.$data.mobileView && windowWidth > desktopBreakpoint) {
                this.$data.mobileView = false;
            }
            if (!this.$data.mobileView && windowWidth < desktopBreakpoint) {
                this.$data.mobileView = true;
            }
        });
        resizeObserver.observe(document.querySelector("body"));

        const pending: Promise<never>[] = [];
        if (this.projectId) {
            pending.push(this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.projectId }));
        }
        if (this.stationId) {
            pending.push(this.loadNotes(this.stationId));
        }
        await Promise.all(pending);
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        confirmLeaveWithDirtyCheck(() => {
            next();
        }, this);
    },
    methods: {
        async loadNotes(stationId: number): Promise<void> {
            this.success = false;
            this.failed = false;
            this.loading = true;
            await this.$services.api.getStationNotes(stationId).then((notes) => {
                Vue.set(this.notes, stationId, notes);
                this.loading = false;
            });
        },
        async onSelected(station): Promise<void> {
            if (this.stationId != station.id) {
                await this.$router.push({
                    name: this.projectId ? "viewProjectStationNotes" : "viewStationNotes",
                    params: {
                        projectId: this.projectId.toString(),
                        stationId: station.id,
                    },
                });
                this.isStationSelected = true;
                return;
            }
            // allows collapsing of selected station tab on mobile
            if (this.isMobileView()) {
                this.isStationSelected = !this.isStationSelected;
            }
        },
        async saveForm(formNotes: Notes): Promise<void> {
            this.success = false;
            this.failed = false;

            await serializePromiseChain(formNotes.addedPhotos, (photo) => {
                return this.$services.api.uploadStationMedia(this.stationId, photo.key, photo.file).then((media) => {
                    console.log(media);
                    return [];
                });
            }).then(() => {
                const payload = mergeNotes(this.notes[this.stationId], formNotes);
                return this.$services.api.patchStationNotes(this.stationId, payload).then(
                    (updated) => {
                        this.success = true;
                        console.log("success", updated);
                    },
                    () => {
                        this.failed = true;
                        console.log("failed");
                    }
                );
            });
        },
        isMobileView(): boolean {
            return this.$data.mobileView;
        },
    },
});
