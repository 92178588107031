
import Vue from "vue";
import CommonComponents from "@/views/shared";
import LoginForm from "./LoginForm.vue";
import Logo from "../shared/Logo.vue";

import { LoginPayload } from "@/api/api";
import { ActionTypes } from "@/store";
import ForbiddenBanner from "@/views/shared/ForbiddenBanner.vue";
import { required, email, sameAs } from "vuelidate/lib/validators";

export default Vue.extend({
    components: {
        ...CommonComponents,
        LoginForm,
        Logo,
        ForbiddenBanner,
    },
    props: {
        errorMessage: {
            type: String,
            required: false,
        },
    },
    data(): {
        busy: boolean;
        failed: boolean;
        authenticated: boolean;
        deleted: boolean;
        email: string;
        form: {
            email: string;
        };
    } {
        return {
            busy: false,
            failed: false,
            authenticated: false,
            deleted: false,
            email: "",
            form: {
                email: "",
            },
        };
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                    sameAsRawValue: sameAs(function (this: any) {
                        return this.email;
                    }),
                },
            },
        };
    },
    methods: {
        async save(payload: LoginPayload): Promise<void> {
            this.busy = true;
            this.failed = false;

            await this.$store
                .dispatch(ActionTypes.LOGIN, payload)
                .then(
                    async () => {
                        await this.afterAuth(payload);
                    },
                    () => (this.failed = true)
                )
                .finally(() => {
                    this.busy = false;
                });
        },
        async afterAuth(payload): Promise<void> {
            this.email = payload.email;
            this.authenticated = true;
        },
        async deleteAccount(): Promise<void> {
            this.$v.form.$touch();
            if (this.busy || this.$v.form.$pending || this.$v.form.$error) {
                return;
            }
            await this.$services.api.deleteAccount({});
            this.deleted = true;

            await this.$store.dispatch({ type: ActionTypes.LOGOUT, skipNavigation: true });
        },
    },
});
