import { render, staticRenderFns } from "./ProjectUpdateEditView.vue?vue&type=template&id=359edcda&scoped=true&"
import script from "./ProjectUpdateEditView.vue?vue&type=script&lang=js&"
export * from "./ProjectUpdateEditView.vue?vue&type=script&lang=js&"
import style0 from "./ProjectUpdateEditView.vue?vue&type=style&index=0&id=359edcda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359edcda",
  null
  
)

export default component.exports