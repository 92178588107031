import { render, staticRenderFns } from "./FollowControl.vue?vue&type=template&id=7f28cc76&scoped=true&"
import script from "./FollowControl.vue?vue&type=script&lang=ts&"
export * from "./FollowControl.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f28cc76",
  null
  
)

export default component.exports