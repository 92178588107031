
import Vue from "vue";

export default Vue.extend({
    name: "InfoTooltip",
    props: {
        message: {
            type: String,
            required: true,
        },
    },
});
