
import Vue from "@/store/strong-vue";
import CommonComponents from "@/views/shared";
import StandardLayout from "../StandardLayout.vue";
import ProjectPublic from "./ProjectPublic.vue";
import ProjectAdmin from "./ProjectAdmin.vue";
import ProjectActivity from "./ProjectActivity.vue";
import { mapState, mapGetters } from "vuex";
import * as ActionTypes from "@/store/actions";
import { GlobalState } from "@/store/modules/global";
import { AuthenticationRequiredError, ForbiddenError } from "@/api";
import { getPartnerCustomizationWithDefault, isCustomisationEnabled, PartnerCustomization } from "@/views/shared/partners";
import { confirmLeaveWithDirtyCheck } from "@/store/modules/dirty";
import ForbiddenBanner from "@/views/shared/ForbiddenBanner.vue";

export default Vue.extend({
    name: "ProjectView",
    components: {
        ...CommonComponents,
        StandardLayout,
        ProjectPublic,
        ProjectAdmin,
        ProjectActivity,
        ForbiddenBanner,
    },
    props: {
        id: {
            required: true,
            type: Number,
        },
        forcePublic: {
            required: true,
            type: Boolean,
        },
        activityVisible: {
            type: Boolean,
            default: false,
        },
        bigMap: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            stations: (s: GlobalState) => Object.values(s.stations.user.stations),
            userProjects: (s: GlobalState) => Object.values(s.stations.user.projects),
            displayProject() {
                return this.$getters.projectsById[this.id];
            },
            isAdministrator() {
                if (!this.forcePublic) {
                    const p = this.$getters.projectsById[this.id];
                    if (p) {
                        return !p.project.readOnly;
                    }
                }
                return false;
            },
        }),
        partnerCustomization(): PartnerCustomization {
            return getPartnerCustomizationWithDefault();
        },
    },
    watch: {
        id() {
            return this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.id });
        },
    },
    beforeMount() {
        return this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.id }).catch((e) => {
            if (ForbiddenError.isInstance(e)) {
                if (!this.$store.getters.isAuthenticated) {
                    return this.$router.push({
                        name: "login",
                        params: { errorMessage: this.$t("login.privateProject").toString() },
                        query: { after: this.$route.path },
                    });
                }
            }
        });
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        confirmLeaveWithDirtyCheck(() => {
            next();
        }, this);
    },
    // needed for project nav from sidebar menu (route is not changed, only route param)
    beforeRouteUpdate(to: any, from: any, next: any) {
        confirmLeaveWithDirtyCheck(() => {
            next();
        }, this);
    },
    methods: {
        goBack() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push("/");
            }
        },
        showStation(station) {
            return this.$router.push({ name: "mapStation", params: { id: station.id } });
        },
        onActivityToggle(this: any) {
            if (this.activityVisible) {
                return this.$router.push({ name: "viewProject", params: { id: this.id } });
            } else {
                return this.$router.push({ name: "viewProjectActivity", params: { id: this.id } });
            }
        },
        closeActivity(this: any) {
            return this.$router.push({ name: "viewProject", params: { id: this.id } });
        },
    },
});
