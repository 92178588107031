
import Vue from "vue";

export default Vue.extend({
    name: "SelectField",
    props: {
        selectedLabel: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    methods: {
        onChange(this: any, ev: Event): void {
            const selectedOption = this.options.filter((option) => {
                if (!ev || !ev.target) {
                    return false;
                }
                const t = ev.target as HTMLSelectElement;
                return option.label === t.value;
            });
            if (selectedOption.length > 0) {
                this.$emit("input", selectedOption[0].value);
            }
        },
    },
});
