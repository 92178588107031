
import _ from "lodash";
import Vue, { PropType } from "vue";
import { DisplayProject } from "@/store";
import { isCustomisationEnabled } from "@/views/shared/partners";

export default Vue.extend({
    name: "ProjectActivity",
    props: {
        containerClass: {
            type: String,
            default: "",
        },
        user: {
            required: true,
        },
        displayProject: {
            type: Object as PropType<DisplayProject>,
            required: true,
        },
    },
    data(): {
        activities: {
            activities: any[];
        };
    } {
        return {
            activities: {
                activities: [],
            },
        };
    },
    computed: {
        loading(): boolean {
            return false;
        },
        visibleActivities(): any[] {
            const compass = this.$loadAsset("icon-compass.svg");
            return _.take(
                this.activities.activities.map((a, i) => {
                    if (a.type == "StationIngestion") {
                        return {
                            id: "ingestion-" + i,
                            type: "ingestion",
                            icon: compass,
                            name: a.station.name,
                            time: new Date(a.createdAt),
                            records: a.meta.data.records,
                            errors: a.meta.errors,
                        };
                    } else if (a.type == "ProjectUpdate") {
                        return {
                            id: "update-" + i,
                            type: "update",
                            icon: compass,
                            name: a.meta.author.name,
                            time: new Date(a.createdAt),
                            text: a.meta.body,
                        };
                    } else if (a.type == "StationDeployed") {
                        return {
                            id: "deploy-" + i,
                            type: "deploy",
                            icon: compass,
                            name: a.station.name,
                            time: new Date(a.meta.deployedAt),
                            location: a.meta.location,
                        };
                    }
                }),
                50
            );
        },
    },
    async mounted(): Promise<void> {
        await this.refresh();
    },
    methods: {
        async refresh(): Promise<void> {
            await this.$services.api.getProjectActivity(this.displayProject.id).then((activities) => {
                this.activities = activities;
                return activities;
            });
        },
        onClose(): void {
            this.$emit("close");
        },
        isCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
    },
});
