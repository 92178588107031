
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { BoundingRectangle, GlobalState, LngLat, MappedStations } from "@/store";
import CommonComponents from "@/views/shared";
import VueTagsInput from "@johmun/vue-tags-input";
import { UploadedImage } from "@/views/shared/ImageUploader.vue";
import { tryParseTags } from "@/utilities";

import { helpers, required, maxLength } from "vuelidate/lib/validators";

import * as ActionTypes from "@/store/actions";

import PlaceholderImage from "@/assets/image-placeholder.svg";
import { mapState } from "vuex";
import StationsMap from "@/views/shared/StationsMap.vue";
import { SnackbarStyle } from "@/store/modules/snackbar";

const afterOtherDate = (afterOtherDate) =>
    helpers.withParams({ type: "afterOtherDate", after: afterOtherDate }, function(this: any, value, parentVm) {
        const other = helpers.ref(afterOtherDate, this, parentVm);
        if (!other || other.length === 0) {
            return true;
        }
        if (!value || value.length == 0) {
            return true;
        }
        return moment(other).isSameOrBefore(moment(value));
    });

export default Vue.extend({
    name: "ProjectForm",
    components: {
        VueTagsInput,
        StationsMap,
        ...CommonComponents,
    },
    props: {
        project: {
            type: Object,
            required: false,
        },
    },
    data(): {
        image: UploadedImage | null;
        tagsFocused: boolean;
        imagePlaceholder: string;
        form: {
            name: string;
            description: string;
            goal: string;
            location: string;
            startTime: string;
            endTime: string;
            tags: { text: string }[];
            tag: string;
            public: boolean;
            privacy: number;
            showStations: boolean;
            bounds: BoundingRectangle;
        };
    } {
        return {
            image: null,
            tagsFocused: false,
            imagePlaceholder: PlaceholderImage,
            form: {
                name: "",
                description: "",
                goal: "",
                location: "",
                startTime: "",
                endTime: "",
                tags: [],
                tag: "",
                public: false,
                privacy: 1,
                showStations: false,
                bounds: MappedStations.defaultBounds(),
            },
        };
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100),
            },
            description: {
                required,
                maxLength: maxLength(100),
            },
            goal: {
                required,
                maxLength: maxLength(100),
            },
            location: {
                required,
                maxLength: maxLength(100),
            },
            tags: {
                maxLength: (value) => {
                    const raw = JSON.stringify(value.map((tag) => tag.text));
                    return raw.length <= 100;
                },
            },
        },
    },
    mounted(): void {
        if (this.project) {
            this.form = {
                name: this.project.name,
                description: this.project.description,
                goal: this.project.goal,
                location: this.project.location,
                startTime: this.project.startTime,
                endTime: this.project.endTime,
                tags: tryParseTags(this.project.tags),
                public: this.project.privacy > 0,
                privacy: this.project.privacy == 0 ? 1 : this.project.privacy,
                showStations: this.project.showStations,
                bounds: new BoundingRectangle(this.project.bounds?.min, this.project.bounds?.max),
                tag: "",
            };
        }
    },
    computed: {
        smallTagsLabel(): boolean {
            return (this.form.tags && this.form.tags.length > 0) || this.tagsFocused;
        },
        imageUrl(): string | null {
            if (this.project.photo) {
                return this.$config.baseUrl + this.project.photo;
            }
            return null;
        },
        ...mapState({
            stations: (s: GlobalState) => Object.values(s.stations.user.stations),
            mappedStations(): MappedStations | null {
                return this.project ? this.$getters.projectsById[this.project.id]?.mapped : MappedStations.make([]);
            },
        }),
    },
    methods: {
        onTagsFocus(): void {
            this.tagsFocused = true;
        },
        onTagsBlur(): void {
            this.tagsFocused = false;
        },
        async saveForm(): Promise<void> {
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) {
                console.log("save form, validation error");
                (document.getElementById("projectForm") as HTMLElement).scrollIntoView();
                return;
            }

            console.log("saving form");

            if (this.project && this.project.id) {
                await this.updateProject();
            } else {
                await this.addProject();
            }
        },
        onTagsChanged(newTags): void {
            this.form.tags = newTags;
        },
        createParams() {
            const makeLocalTime = (str) => {
                if (!str || str.length == 0) {
                    return null;
                }
                return moment(str, "M/D/YYYY").toISOString();
            };
            return _.extend({}, this.form, {
                id: this.project?.id || null,
                privacy: this.form.public ? Number(this.form.privacy || 0) : 0,
                startTime: makeLocalTime(this.form.startTime),
                endTime: makeLocalTime(this.form.endTime),
                tags: JSON.stringify(this.form.tags.map((tag) => tag.text)),
                showStations: this.form.showStations,
                bounds: this.form.bounds,
            });
        },
        async addProject(): Promise<void> {
            this.$emit("updating");

            const data = this.createParams();
            const image = this.image;
            if (image) {
                await this.$store.dispatch(ActionTypes.ADD_PROJECT, data).then((project) => {
                    const params = {
                        type: image.type,
                        file: image.file,
                        id: project.id,
                    };
                    return this.$services.api.uploadProjectImage(params).then(() => {
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("project.addSuccess"),
                            type: SnackbarStyle.success,
                        });
                        return this.$router.push({
                            name: "viewProject",
                            params: { id: project.id },
                        });
                    });
                });
            } else {
                await this.$store.dispatch(ActionTypes.ADD_PROJECT, data).then((project) => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("project.addSuccess"),
                        type: SnackbarStyle.success,
                    });
                    return this.$router.push({
                        name: "viewProject",
                        params: { id: project.id },
                    });
                });
            }
        },
        async updateProject(): Promise<void> {
            console.log("updating");

            this.$emit("updating");

            const data = this.createParams();
            const image = this.image;
            if (image) {
                const payload = {
                    type: image.type,
                    file: image.file,
                    id: this.project.id,
                };
                await this.$services.api.uploadProjectImage(payload).then(() => {
                    this.saveProject(data);
                });
            } else {
                await this.saveProject(data);
            }
        },

        async saveProject(data): Promise<void> {
            await this.$store.dispatch(ActionTypes.SAVE_PROJECT, data).then(() => {
                this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("project.updateSuccess"),
                    type: SnackbarStyle.success,
                });
                return this.$router.push({
                    name: "viewProject",
                    params: { id: this.project.id },
                });
            });
        },
        async deleteProject(): Promise<void> {
            if (window.confirm(this.$tc("project.form.confirmDelete"))) {
                await this.$store.dispatch(ActionTypes.DELETE_PROJECT, { projectId: this.project.id }).then(() => {
                    return this.$router.push({ name: "projects" });
                });
            }
        },
        async closeForm(): Promise<void> {
            if (this.project) {
                await this.$router.push({ name: "viewProject", params: { id: this.project.id } });
            } else {
                await this.$router.push({ name: "projects" });
            }
        },
        onImage(image): void {
            this.image = image;
            this.$emit("change");
        },
    },
});
