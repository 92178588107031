
import Vue from "vue";

export default Vue.extend({
    name: "AudioPlayer",
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data: () => {
        return {
            loaded: null,
        };
    },
    mounted(this: any) {
        return this.$services.api.loadMedia(this.url).then((loaded) => {
            this.loaded = loaded.replace("m4a", "mp4");
        });
    },
});
