
import { ProjectAttribute } from "@/api/api";
import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "ProjectAttributes",
    props: {
        attributes: {
            type: Array as PropType<ProjectAttribute[]>,
            required: true,
        },
    },
});
