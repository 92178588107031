
import Vue from "vue";
import * as ActionTypes from "@/store/actions";
import { AuthenticationRequiredError } from "@/api";
import { getPartnerCustomization, PartnerCustomization } from "./views/shared/partners";
import SnackBar from "@/views/shared/SnackBar.vue";
import {Locales} from '@/views/shared/LanguageSelector.vue';
import moment from 'moment';
import i18n from '@/i18n';

export default Vue.extend({
    components: {
        SnackBar,
    },
    data() {
        return {
            Locales: Locales,
        };
    },
    async beforeMount(): Promise<void> {
        try {
            this.useSavedLocale();
            this.applyCustomClasses();
            await this.$store.dispatch(ActionTypes.INITIALIZE);
        } catch (err) {
            console.log("initialize error", err, err.stack);
        }
    },
    mounted(): void {
        this.setCustomFavicon();
        this.setCustomPageTitle();
    },
    computed: {
        partnerCustomization(): PartnerCustomization | null {
            return getPartnerCustomization();
        },
    },
    beforeUpdate(): void {
        this.applyCustomClasses();
    },
    errorCaptured(err): boolean {
        console.log("vuejs:error-captured", JSON.stringify(err));
        if (AuthenticationRequiredError.isInstance(err)) {
            this.$router.push({ name: "login", query: { after: this.$route.path } });
            return false;
        }
        return true;
    },
    methods: {
        applyCustomClasses(): void {
            if (this.partnerCustomization != null) {
                document.body.classList.add(this.partnerCustomization.class);
            }
        },
        setCustomFavicon(): void {
            const faviconEl = document.getElementById("favicon") as HTMLAnchorElement;
            if (this.partnerCustomization != null) {
                faviconEl.href = window.location.origin + this.partnerCustomization.icon;
            }
        },
        setCustomPageTitle(): void {
            if (this.partnerCustomization != null) {
                document.title = this.partnerCustomization.title;
            }
        },
        changeLang(locale: Locales) {
            i18n.locale = locale;
            localStorage.setItem("locale", locale);
            moment.locale(locale);
        },
        useSavedLocale() {
            const locale = localStorage.getItem("locale") as Locales;
            if (locale) {
                this.changeLang(locale);
            }
        },
    },
});
