
import ProjectThumbnail from "./ProjectThumbnail.vue";

export default {
    name: "ProjectThumbnails",
    components: {
        ProjectThumbnail,
    },
    props: {
        projects: {
            type: Array,
            required: true,
        },
        invited: {
            type: Boolean,
            default: false,
        },
    },
};
