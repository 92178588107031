
import Vue from "vue";
import CommonComponents from "@/views/shared";

import { mapGetters, mapState } from "vuex";
import { GlobalState } from "@/store";
import * as ActionTypes from "@/store/actions";

export default Vue.extend({
    name: "TermsView",
    components: {
        ...CommonComponents,
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isTncValid: "isTncValid" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
        }),
        tncOutdated() {
            return this.isAuthenticated && !this.isTncValid;
        },
    },
    methods: {
        async agree(): Promise<void> {
            if (this.user) {
                const payload = {
                    id: this.user?.id,
                    name: this.user?.name,
                    email: this.user?.email,
                    bio: this.user?.bio,
                    tncDate: this.user?.tncDate,
                    tncAccept: true,
                };
                await this.$services.api.accept(this.user.id);
                await this.$store.dispatch(ActionTypes.REFRESH_CURRENT_USER, {});
                await this.$router.push({ name: "projects" }).catch((e) => {
                    console.log(e);
                });
            }
        },
        async disagree(): Promise<void> {
            await this.$router.push({ name: "login" });
        },
        goBack(): void {
            if (window.history.length) {
                this.$router.go(-1);
            } else {
                this.$router.push("/");
            }
        },
    },
});
