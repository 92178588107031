
import Vue from "vue";

export default Vue.extend({
    name: "SectionToggle",
    props: {
        leftLabel: {
            type: String,
            required: true,
        },
        rightLabel: {
            type: String,
            required: true,
        },
        default: {
            type: String,
            default: "left",
        },
        showToggle: {
            type: Boolean,
            required: true,
        },
    },
    data(): {
        selectedSection: any;
    } {
        return {
            selectedSection: this.default,
        };
    },
    methods: {
        toggleClickHandler(evt, section) {
            this.selectedSection = section;
            this.$emit("toggle", section);
        },
        matchSection(section) {
            return this.selectedSection === section;
        },
    },
});
