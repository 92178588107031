
import Vue, { PropType } from "vue";
import { DisplayStation, StationStatus } from "@/store";
import * as utils from "@/utilities";

export default Vue.extend({
    name: "StationBattery.vue",
    props: {
        station: {
            type: Object as PropType<DisplayStation>,
            default: null,
        },
    },
    filters: {
        integer: (value) => {
            if (!value) return "";
            return Math.round(value);
        },
    },
    data: () => {
        return {
            StationStatus: StationStatus,
        };
    },
    methods: {
        getBatteryIcon() {
            return this.$loadAsset(utils.getBatteryIcon(this.station.battery));
        },
    },
});
