
import _ from "lodash";
import Vue, { PropType } from "vue";
import CommonComponents from "@/views/shared";
import StationPickerStation from "./StationPickerStation.vue";
import PaginationControls from "./PaginationControls.vue";
import { DisplayStation } from "@/store";

export enum StationPickerActionType {
    add = "add",
    remove = "remove",
}

export default Vue.extend({
    name: "StationPicker",
    components: {
        ...CommonComponents,
        StationPickerStation,
        PaginationControls,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        actionType: {
            type: String,
            required: true,
        },
        actionText: {
            type: String,
            required: true,
        },
        stations: {
            type: Array as PropType<DisplayStation[]>,
            required: true,
        },
        filter: {
            type: Function as PropType<(station: DisplayStation) => boolean>,
            default: (station) => true,
        },
    },
    data(): {
        selected: number[];
        paging: {
            number: number;
            size: number;
        };
        search: string;
    } {
        return {
            selected: [],
            paging: {
                number: 0,
                size: window.screen.availWidth > 500 ? 6 : 3,
            },
            search: "",
        };
    },
    computed: {
        visible(): DisplayStation[] {
            const start = this.paging.number * this.paging.size;
            const end = start + this.paging.size;
            return this.filteredStations().slice(start, end);
        },
    },
    methods: {
        filteredStations(): DisplayStation[] {
            if (this.search.length === 0) {
                return this.stations.filter((station) => this.filter(station));
            }
            return _.filter(
                this.stations.filter((station) => this.filter(station)),
                (station) => {
                    console.log("fff", station);
                    return station.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0;
                }
            );
        },
        totalPages(): number {
            return Math.ceil(this.filteredStations().length / this.paging.size);
        },
        onSelected(station: DisplayStation): void {
            const idAtIndex = this.selected.findIndex((value) => value === station.id);
            if (idAtIndex > -1) {
                this.selected.splice(idAtIndex, 1);
            } else {
                this.selected.push(station.id);
            }
        },
        onClose(): void {
            this.$emit("close");
        },
        onCtaClick(): void {
            if (!this.selected) {
                return;
            }
            this.$emit("ctaClick", this.selected);
        },
        onNewPage(page: number): void {
            this.paging.number = page;
        },
    },
});
