
import Promise from "bluebird";

import _ from "lodash";
import Vue from "vue";
import CommonComponents from "@/views/shared";
import StandardLayout from "../StandardLayout.vue";
import ExportPanel from "./ExportPanel.vue";
import SharePanel from "./SharePanel.vue";
import StationSummaryContent from "../shared/StationSummaryContent.vue";
import PaginationControls from "@/views/shared/PaginationControls.vue";
import { getPartnerCustomization, getPartnerCustomizationWithDefault, interpolatePartner, PartnerCustomization } from "../shared/partners";
import { mapState, mapGetters } from "vuex";
import { DisplayStation } from "@/store";
import { GlobalState } from "@/store/modules/global";
import { SensorsResponse } from "./api";
import { Workspace, Bookmark, Time, VizSensor, ChartType, FastTime, VizSettings } from "./viz";
import { VizWorkspace } from "./VizWorkspace";
import { isMobile, getBatteryIcon } from "@/utilities";
import Comments from "../comments/Comments.vue";
import StationBattery from "@/views/station/StationBattery.vue";
import InfoTooltip from "@/views/shared/InfoTooltip.vue";
import Spinner from "@/views/shared/Spinner.vue";
import { confirmLeaveWithDirtyCheck } from "@/store/modules/dirty";

export default Vue.extend({
    name: "ExploreWorkspace",
    components: {
        ...CommonComponents,
        StandardLayout,
        VizWorkspace,
        SharePanel,
        ExportPanel,
        Comments,
        StationSummaryContent,
        PaginationControls,
        StationBattery,
        InfoTooltip,
        Spinner,
    },
    props: {
        token: {
            type: String,
            required: false,
        },
        bookmark: {
            type: Bookmark,
            required: true,
        },
        exportsVisible: {
            type: Boolean,
            default: false,
        },
        shareVisible: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        workspace: Workspace | null;
        showNoSensors: boolean;
        selectedIndex: number;
        validStations: number[];
    } {
        return {
            workspace: null,
            showNoSensors: false,
            selectedIndex: 0,
            validStations: [],
        };
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            stations: (s: GlobalState) => s.stations.user.stations,
            userProjects: (s: GlobalState) => s.stations.user.projects,
        }),
        addIcon(): unknown {
            return this.$loadAsset("icon-compare.svg");
        },
        busy(): boolean {
            if (isMobile()) {
                return !this.workspace;
            }
            return !this.workspace || this.workspace.busy;
        },
        backLabelKey(): string {
            const partnerCustomization = getPartnerCustomization();
            if (this.bookmark && this.bookmark.c) {
                if (!this.bookmark.c.map) {
                    return "layout.backProjectDashboard";
                }
            }
            if (partnerCustomization) {
                return partnerCustomization.nav.viz.back.map.label;
            }
            return "layout.backToStations";
        },
        selectedId(): number {
            return Number(_.flattenDeep(this.bookmark.g)[0]);
        },
        selectedStation(): DisplayStation | null {
            if (this.workspace) {
                return this.workspace.getStation(this.workspace.selectedStationId);
            }
            return null;
        },
        currentStation(): DisplayStation | null {
            return this.bookmark.s.length > 0 ? this.$getters.stationsById[this.bookmark.s[0]] : null;
        },
    },
    watch: {
        async bookmark(newValue: Bookmark, oldValue: Bookmark): Promise<void> {
            console.log(`viz: bookmark-route(ew):`, newValue);
            if (this.workspace) {
                await this.workspace.updateFromBookmark(newValue);
            } else {
                await this.createWorkspaceIfNecessary();
            }
        },
        async selectedId(newValue: number, oldValue: number): Promise<void> {
            console.log("viz: selected-changed-associated", newValue);
        },
    },
    async beforeMount(): Promise<void> {
        if (this.bookmark) {
            await this.$services.api
                .getAllSensorsMemoized()() // TODO No need to make this call.
                .then(async () => {
                    // Check for a bookmark that is just to a station with no groups.
                    if (this.bookmark.s.length > 0 && this.bookmark.g.length == 0) {
                        console.log("viz: before-show-station", this.bookmark);
                        return this.showStation(this.bookmark.s[0]);
                    }

                    console.log("viz: before-create-workspace", this.bookmark);
                    await this.createWorkspaceIfNecessary();
                })
                .catch(async (e) => {
                    if (e.name === "ForbiddenError") {
                        await this.$router.push({ name: "login", params: { errorMessage: String(this.$t("login.privateStation")) } });
                    }
                });
        }
    },
    methods: {
        async onBack() {
            if (this.bookmark.c) {
                if (this.bookmark.c.map) {
                    await this.$router.push({ name: "viewProjectBigMap", params: { id: String(this.bookmark.c.project) } });
                } else {
                    await this.$router.push({ name: "viewProject", params: { id: String(this.bookmark.c.project) } });
                }
            } else {
                await this.$router.push({ name: "mapAllStations" });
            }
        },
        async addChart() {
            console.log("viz: add");
            if (!this.workspace) throw new Error("viz-add: no workspace");
            return this.workspace.addChart().query();
        },
        async onChange(bookmark: Bookmark): Promise<void> {
            if (Bookmark.sameAs(this.bookmark, bookmark)) {
                // console.log("viz: bookmark-no-change", bookmark);
                return Promise.resolve(this.workspace);
            }
            console.log("viz: bookmark-change", bookmark);
            await this.openBookmark(bookmark);
        },
        async openBookmark(bookmark: Bookmark): Promise<void> {
            this.$emit("open-bookmark", bookmark);
        },
        async openExports(): Promise<void> {
            this.$emit("export");
        },
        async openShare(): Promise<void> {
            this.$emit("share");
        },
        async closePanel(): Promise<void> {
            return await this.openBookmark(this.bookmark);
        },
        async createWorkspaceIfNecessary(): Promise<Workspace> {
            if (this.workspace) {
                return this.workspace;
            }

            console.log("viz: workspace-creating");

            const settings = new VizSettings(isMobile());
            const allSensors: SensorsResponse = await this.$services.api.getAllSensorsMemoized()();
            const ws = this.bookmark ? Workspace.fromBookmark(allSensors, this.bookmark, settings) : new Workspace(allSensors, settings);

            this.workspace = await ws.initialize();

            console.log(`viz: workspace-created`);

            return ws;
        },
        async showStation(stationId: number): Promise<void> {
            console.log("viz: show-station", stationId);

            return confirmLeaveWithDirtyCheck(async () => {
                return await this.$services.api
                    .getQuickSensors([stationId])
                    .then(async (quickSensors) => {
                        console.log("viz: quick-sensors", quickSensors);
                        if (quickSensors.stations[stationId].filter((r) => r.moduleId != null).length == 0) {
                            console.log("viz: no sensors TODO: FIX");
                            this.showNoSensors = true;
                            return Promise.delay(5000).then(() => {
                                this.showNoSensors = false;
                            });
                        }

                        const sensorModuleId = quickSensors.stations[stationId][0].moduleId;
                        const sensorId = quickSensors.stations[stationId][0].sensorId;
                        const vizSensor: VizSensor = [stationId, [sensorModuleId, sensorId]];

                        const associated = await this.$services.api.getAssociatedStations(stationId);
                        const stationIds = associated.stations.map((associatedStation) => associatedStation.station.id);
                        console.log(`viz: show-station-associated`, { associated, stationIds });

                        const getInitialBookmark = () => {
                            const quickSensor = quickSensors.stations[stationId].filter((qs) => qs.sensorId == sensorId);
                            if (quickSensor.length == 1) {
                                const end = new Date(quickSensor[0].sensorReadAt);
                                const start = new Date(end);

                                if (isMobile()) {
                                    start.setDate(end.getDate() - 1); // TODO Use getFastTime
                                } else {
                                    start.setDate(end.getDate() - 14); // TODO Use getFastTime
                                }

                                return new Bookmark(
                                    this.bookmark.v,
                                    [[[[[vizSensor], [start.getTime(), end.getTime()], [], ChartType.TimeSeries, FastTime.TwoWeeks]]]],
                                    stationIds,
                                    this.bookmark.p,
                                    this.bookmark.c
                                );
                            }

                            console.log("viz: ERROR missing expected quick row, default to FastTime.All");

                            return new Bookmark(
                                this.bookmark.v,
                                [[[[[vizSensor], [Time.Min, Time.Max], [], ChartType.TimeSeries, FastTime.All]]]],
                                stationIds,
                                this.bookmark.p,
                                this.bookmark.c
                            );
                        };

                        this.$emit("open-bookmark", getInitialBookmark());
                    })
                    .catch(async (e) => {
                        if (e.name === "ForbiddenError") {
                            await this.$router.push({ name: "login", params: { errorMessage: String(this.$t("login.privateStation")) } });
                        }
                    });
            }, this);
        },
        getValidStations(): number[] {
            if (this.workspace == null) {
                return [];
            }

            const validStations = this.workspace.stationsMetas
                .filter((station) => !station.hidden && station.sensors.length > 0)
                .map((d) => d.id);

            this.selectedIndex = validStations.indexOf(this.selectedId);

            return validStations;
        },
        onNewSummaryStation(evt) {
            const stations = this.getValidStations();
            this.showStation(stations[evt]);
            this.selectedIndex = evt;
        },
        openStationPageTab() {
            const station = this.selectedStation ? this.selectedStation : this.currentStation;
            if (station) {
                const routeData = this.$router.resolve({
                    name: "viewStationFromMap",
                    params: { stationId: String(station.id) },
                });
                window.open(routeData.href, "_blank");
            }
        },
        getBatteryIcon() {
            if (this.selectedStation == null) {
                return null;
            }
            return this.$loadAsset(getBatteryIcon(this.selectedStation.battery));
        },
        interpolatePartner(baseString) {
            return interpolatePartner(baseString);
        },
        partnerCustomization(): PartnerCustomization {
            return getPartnerCustomizationWithDefault();
        },
        eventClicked(id: number): void {
            this.$emit("event-clicked", id);
        },
        exportSupported(): boolean {
            if (this.workspace == null) {
                return false;
            }

            if (!this.partnerCustomization().exportSupported) {
                return false;
            }

            const stationModels = _.uniq(this.workspace.allStations.map((s) => s.model.name));
            const anyNodeRed = stationModels.filter((name) => name.indexOf("NodeRed") >= 0); // TODO This should be a flag.

            console.log("viz:export-disabled", stationModels, anyNodeRed);

            return anyNodeRed.length == 0;
        },
    },
});
