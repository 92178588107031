
import Vue from "vue";
import CommonComponents from "@/views/shared";
import { mapGetters, mapState } from "vuex";
import { ActionTypes, GlobalState } from "@/store";
import Tiptap from "@/views/shared/Tiptap.vue";
import moment from "moment";
import _ from "lodash";
import { PortalStationFieldNotes } from "@/views/fieldNotes/model";
import { jsPDF } from "jspdf";
import { SnackbarStyle } from "@/store/modules/snackbar";
import { field } from "vega";

interface GroupedFieldNotes {
    [date: string]: PortalStationFieldNotes[];
}

export default Vue.extend({
    name: "FieldNotes",
    components: {
        ...CommonComponents,
        Tiptap,
    },
    props: {
        stationName: {
            type: String,
            required: true,
        },
    },
    computed: {
        ActionTypes() {
            return ActionTypes;
        },
        ...mapGetters({ isAuthenticated: "isAuthenticated" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
        }),
        fieldNotes(): PortalStationFieldNotes[] {
            return this.$state.fieldNotes.fieldNotes;
        },
        stationId(): number {
            return parseInt(this.$route.params.stationId, 10);
        },
    },
    data(): {
        groupedFieldNotes: GroupedFieldNotes[] | null;
        isLoading: boolean;
        placeholder: string | null;
        newNoteText: string | null;
        errorMessage: string | null;
        editingFieldNote: PortalStationFieldNotes | null;
        dirtyRefs: string[];
    } {
        return {
            groupedFieldNotes: null,
            isLoading: true,
            placeholder: null,
            newNoteText: null,
            errorMessage: null,
            editingFieldNote: null,
            dirtyRefs: [],
        };
    },
    beforeMount(): void {
        this.$store.dispatch(ActionTypes.NEED_FIELD_NOTES, { id: this.stationId });
    },
    watch: {
        fieldNotes() {
            this.groupByMonth();
        },
    },
    methods: {
        checkEditingFieldNote() {
            if (this.editingFieldNote) {
                this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.finishEditingFirst"),
                    type: SnackbarStyle.fail,
                });
            }
        },
        async save(): Promise<void> {
            if (this.editingFieldNote) {
                await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.finishEditingFirst"),
                    type: SnackbarStyle.fail,
                });
                return;
            }
            this.errorMessage = null;
            const note = {
                body: JSON.stringify(this.newNoteText),
                userId: this.user?.id,
                stationId: this.stationId,
            };

            try {
                await this.$store.dispatch(ActionTypes.ADD_FIELD_NOTE, { stationId: this.stationId, note });
                await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.addSuccess"),
                    type: SnackbarStyle.success,
                });
                await this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "newFieldNote");
            } catch (e) {
                return this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("somethingWentWrong"),
                    type: SnackbarStyle.fail,
                });
            }
        },
        async saveEdit(fieldNote: PortalStationFieldNotes): Promise<void> {
            const editorRef = this.$refs["note-ref-" + fieldNote.id];
            if (!editorRef || !editorRef[0] || !editorRef[0].editor) {
                console.error("Tip tap ref not found");
                return;
            }

            const editedText = editorRef[0].editor.getJSON();
            const payload = {
                id: this.editingFieldNote?.id,
                body: JSON.stringify(editedText),
                userId: this.user?.id,
                stationId: this.stationId,
            };

            // not the nicest way, but the tiptap editor does not revert itself to the initial body text, so need to reload everything
            if (editorRef[0].editor.isEmpty) {
                await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.emptyNoteCannotSave"),
                    type: SnackbarStyle.fail,
                });
                return;
            }

            try {
                await this.$store.dispatch(ActionTypes.UPDATE_FIELD_NOTE, { stationId: this.stationId, note: payload });
                await this.$store.dispatch(ActionTypes.NEED_FIELD_NOTES, { id: this.stationId });
                await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.editSuccess"),
                    type: SnackbarStyle.success,
                });
                await this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editFieldNote#" + fieldNote.id);
            } catch (e) {
                return this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("somethingWentWrong"),
                    type: SnackbarStyle.fail,
                });
            }
        },
        editFieldNote(fieldNote: PortalStationFieldNotes) {
            if (this.editingFieldNote) {
                this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                    message: this.$tc("fieldNotes.finishEditingFirstBeforeNewOne"),
                    type: SnackbarStyle.fail,
                });
                return;
            }
            this.editingFieldNote = {
                ...JSON.parse(JSON.stringify(fieldNote)),
                ref: "note-ref-" + fieldNote.id,
            };
        },
        deleteFieldNote(noteId: number) {
            this.$confirm({
                message: this.$tc("fieldNotes.sureDelete"),
                button: {
                    no: this.$tc("no"),
                    yes: this.$tc("yes"),
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.$store.dispatch(ActionTypes.DELETE_FIELD_NOTE, { stationId: this.stationId, noteId });
                            await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                                message: this.$tc("fieldNotes.deleteSuccess"),
                                type: SnackbarStyle.success,
                            });
                        } catch (e) {
                            await this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                                message: this.$tc("somethingWentWrong"),
                                type: SnackbarStyle.fail,
                            });
                        }
                    }
                },
            });
        },
        formatTimestamp(timestamp: number): string {
            return moment(timestamp).fromNow();
        },
        canEdit(fieldNote: PortalStationFieldNotes) {
            return fieldNote.author.id === this.user?.id;
        },
        // Duplicate functions that currently do the same thing since I expect the condition to change here
        canDelete(fieldNote: PortalStationFieldNotes) {
            return fieldNote.author.id === this.user?.id;
        },
        cancelEdit(fieldNote: PortalStationFieldNotes) {
            const editorRef = this.$refs["note-ref-" + fieldNote.id];
            if (!editorRef || !editorRef[0] || !editorRef[0].editor) {
                console.error("Tip tap ref not found");
                return;
            }
            const currentContent = JSON.stringify(editorRef[0].editor.getJSON());

            if (fieldNote.body !== currentContent) {
                this.$confirm({
                    message: this.$tc("fieldNotes.sureCancelEdit"),
                    button: {
                        no: this.$tc("no"),
                        yes: this.$tc("yes"),
                    },
                    callback: async (confirm) => {
                        if (confirm && this.editingFieldNote) {
                            editorRef[0].editor.commands.setContent(JSON.parse(fieldNote.body));
                            this.editingFieldNote = null;
                            await this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editFieldNote#" + fieldNote.id);
                        }
                    },
                });
                return;
            }

            this.editingFieldNote = null;
        },
        groupByMonth() {
            if (this.fieldNotes.length > 0) {
                this.groupedFieldNotes = _.groupBy(this.fieldNotes, (b) => moment(b.createdAt).startOf("month").format("YYYY/MM")) as any;
            } else {
                this.groupedFieldNotes = null;
            }
            this.$nextTick(() => {
                this.editingFieldNote = null;
                this.isLoading = false;
            });
        },

        getMonthName(month): string {
            return moment(month, "YYYY/MM").format("MMMM");
        },
        getMonthLastUpdated(items): string {
            const mostRecentUpdatedAt = items.reduce((maxUpdatedAt, obj) => {
                return Math.max(maxUpdatedAt, obj.updatedAt);
            }, 0);

            return this.formatTimestamp(mostRecentUpdatedAt);
        },
        toggleFieldNoteGroup(ref) {
            const elements = this.$refs[ref] as (HTMLElement | undefined)[];
            if (elements && elements.length > 0) {
                const element = elements[0];
                if (element instanceof HTMLElement) {
                    element.classList.toggle("hidden");
                }
            }
        },
        async generatePDF() {
            const doc = new jsPDF();
            const elementHTML = this.$refs.pdfContent as HTMLElement;

            const actionsEls = elementHTML.querySelectorAll(".actions");
            const groupEls = elementHTML.querySelectorAll(".field-note-group");

            // prepare HTML
            groupEls.forEach((el) => {
                el.classList.remove("hidden");
            });
            actionsEls.forEach((el) => {
                el.classList.add("hidden");
            });

            doc.html(elementHTML, {
                callback: (doc) => {
                    const fileName = this.stationName + " " + this.$tc("fieldNotes.title") + ".pdf";
                    doc.save(fileName);
                    // display back the actions els
                    actionsEls.forEach((el) => {
                        el.classList.remove("hidden");
                    });
                },
                margin: [10, 10, 10, 10],
                autoPaging: "text",
                x: 0,
                y: 0,
                width: 190, //target width in the PDF document
                windowWidth: 675, //window width in CSS pixels
            });
        },
        onEditFieldNoteInput(fieldNote: PortalStationFieldNotes, event: string) {
            if (JSON.stringify(event) !== fieldNote.body) {
                this.$store.dispatch(ActionTypes.NEW_DIRTY_FIELD, "editFieldNote#" + fieldNote.id);
            } else {
                this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editFieldNote#" + fieldNote.id);
            }
        },
    },
});
