
import _ from "lodash";
import Vue from "vue";
import CommonComponents from "@/views/shared";
import { getPartnerCustomization, getPartnerCustomizationWithDefault } from "@/views/shared/partners";
import { mapState } from "vuex";
import { isCustomisationEnabled } from "@/views/shared/partners";

function getRelativeUrl(href: string): string {
    const link = document.createElement("a");
    link.href = href;
    return link.href;
}

export default Vue.extend({
    name: "SharePanel",
    components: {
        ...CommonComponents,
    },
    props: {
        token: {
            type: String,
            required: true,
        },
        bookmark: {
            type: Object,
            required: true,
        },
        containerClass: {
            type: String,
            required: true,
        },
    },
    data: (): {
        showCopiedLink: boolean;
    } => {
        return {
            showCopiedLink: false,
        };
    },
    computed: {
        ...mapState({}),
        twitterUrl(): string {
            const qs = new URLSearchParams();
            qs.append("url", this.vizUrl);
            // TODO We should probably have a "non-partner" customization.
            const partnerCustomization = getPartnerCustomization();
            if (partnerCustomization != null) {
                qs.append("text", partnerCustomization.sharing.viz);
            } else {
                qs.append("text", this.$tc('sharePanel.twitterText'));
            }
            return `https://twitter.com/intent/tweet?${qs.toString()}`;
        },
        facebookUrl(): string {
            const qs = new URLSearchParams();
            qs.append("u", this.vizUrl);
            return `https://www.facebook.com/sharer/sharer.php?${qs.toString()}`;
        },
        vizUrl(): string {
            const qs = new URLSearchParams();
            qs.append("v", this.token);
            return getRelativeUrl(`/viz?${qs.toString()}`);
        },
    },
    methods: {
        onClose() {
            this.$emit("close");
        },
        onShareTwitter() {
            console.log("ok");
        },
        getCurrentURL(): string {
            const path = this.$route.path.split("/")[2];
            const fullPath = window.location.href.replace("/" + path, "");
            return fullPath;
        },
        copyUrlToClipboard(): void {
            const inputEl = this.$refs["url"] as HTMLInputElement;
            navigator.clipboard.writeText(inputEl.value).then(() => {
                this.showCopiedLink = true;
                setTimeout(() => {
                    this.showCopiedLink = false;
                }, 3000);
            });
        },
        openMailClient(): void {
            const partnerCustomization = getPartnerCustomizationWithDefault();
            const body = this.getCurrentURL();
            const subject = this.$t(partnerCustomization.email.subject);
            window.location.href = "mailto:?subject=" + subject + "&body=" + body;
        },
    },
});
