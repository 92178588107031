
import _ from "lodash";
import * as utils from "../../utilities";

import { mapGetters, mapState } from "vuex";
import {
    ActionTypes,
    BoundingRectangle,
    DisplayStation,
    GlobalState,
    MappedStations,
    Project,
    ProjectModule,
    VisibleReadings,
} from "@/store";
import { SensorDataQuerier } from "@/views/shared/sensor_data_querier";

import Vue from "vue";
import StandardLayout from "../StandardLayout.vue";
import StationsMap from "../shared/StationsMap.vue";
import StationHoverSummary from "@/views/shared/StationHoverSummary.vue";
import TinyChart from "@/views/viz/TinyChart.vue";
import CommonComponents from "@/views/shared";
import ProjectDetailCard from "@/views/projects/ProjectDetailCard.vue";
import { ExploreContext, VizThresholds } from "@/views/viz/common";

import { getPartnerCustomizationWithDefault, isCustomisationEnabled } from "@/views/shared/partners";
import MapViewTypeToggle from "@/views/shared/MapViewTypeToggle.vue";
import { MapViewType } from "@/api/api";

export default Vue.extend({
    name: "ProjectBigMap",
    components: {
        ...CommonComponents,
        StationsMap,
        StationHoverSummary,
        StandardLayout,
        ProjectDetailCard,
        TinyChart,
        MapViewTypeToggle,
    },
    data(): {
        layoutChanges: number;
        activeStationId: number | null;
        recentMapMode: boolean;
        legendCollapsed: boolean;
        isMobileView: boolean;
    } {
        return {
            layoutChanges: 0,
            activeStationId: null,
            recentMapMode: false,
            legendCollapsed: false,
            isMobileView: window.screen.availWidth <= 768,
        };
    },
    props: {
        id: {
            required: true,
            type: Number,
        },
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy" }),
        ...mapState({
            user: (s: GlobalState) => s.user.user,
            userStations: (s: GlobalState) => Object.values(s.stations.user.stations),
            displayProject() {
                return this.$getters.projectsById[this.id];
            },
        }),
        visibleReadings(): VisibleReadings {
            return this.recentMapMode ? VisibleReadings.Last72h : VisibleReadings.Current;
        },
        project(): Project {
            return this.displayProject.project;
        },
        sensorDataQuerier(): SensorDataQuerier {
            return new SensorDataQuerier(this.$services.api);
        },
        projectStations(): DisplayStation[] {
            const stations = this.$getters.projectsById[this.id].stations;
            const sortFactors = _.fromPairs(stations.map((station) => [station.id, station.getSortOrder(this.visibleReadings)]));
            return _.orderBy(
                this.$getters.projectsById[this.id].stations.slice(),
                [(station) => sortFactors[station.id][0], (station) => sortFactors[station.id][1], (station) => sortFactors[station.id][2]],
                ["asc", "desc", "asc"]
            );
        },
        mappedProject(): MappedStations | null {
            return this.$getters.projectsById[this.id].mapped;
        },
        activeStation(): DisplayStation | null {
            if (this.activeStationId) {
                return this.$getters.stationsById[this.activeStationId];
            }
            return null;
        },
        projectModules(): { name: string; url: string }[] {
            return this.$getters.projectsById[this.displayProject.id].modules.map((m) => {
                return {
                    name: m.name,
                    url: this.getModuleImg(m),
                };
            });
        },
        mapBounds(): BoundingRectangle {
            if (this.project.bounds?.min && this.project.bounds?.max) {
                return new BoundingRectangle(this.project.bounds?.min, this.project.bounds?.max);
            }

            return MappedStations.defaultBounds();
        },
        exploreContext(): ExploreContext {
            return new ExploreContext(this.project.id, true);
        },
        stationsWithData(): DisplayStation[] {
            return this.displayProject.stations.filter((station) => station.hasData);
        },
        hasStationsWithoutData(): boolean {
            return this.stationsWithData.length < this.projectStations.length;
        },
        keyTitle(): string {
            if (this.stationsWithData.length > 0) {
                return this.getThresholds(this.stationsWithData).label["enUS"];
            } else {
                return "";
            }
        },
        levels(): object[] {
            if (this.stationsWithData.length > 0) {
                return this.getThresholds(this.stationsWithData).levels.filter((d) => d["label"]);
            } else {
                return [];
            }
        },
        partnerCustomization() {
            return getPartnerCustomizationWithDefault();
        },
        viewType(): MapViewType {
            if (this.$route.meta?.viewType) {
                return this.$route.meta.viewType;
            }
            return MapViewType.map;
        },
    },
    watch: {
        id(): Promise<any> {
            if (this.id) {
                return this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.id });
            }
            return Promise.resolve();
        },
    },
    beforeMount(): Promise<any> {
        if (this.id) {
            return this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.id });
        }
        return Promise.resolve();
    },
    methods: {
        switchView(): void {
            this.activeStationId = null;
            this.layoutChanges++;
        },
        getModuleImg(module: ProjectModule): string {
            return this.$loadAsset(utils.getModuleImg(module));
        },
        showSummary(station: DisplayStation): void {
            console.log("map: show-summay", station);
            this.activeStationId = station.id;
        },
        onCloseSummary(): void {
            this.activeStationId = null;
        },
        getThresholds(stations: DisplayStation[]): VizThresholds {
            try {
                return stations[0].configurations.all[0].modules[0].sensors[0].meta.viz[0].thresholds;
            } catch (error) {
                return {
                    label: {},
                    levels: [],
                };
            }
        },
        sidebarToggle() {
            setTimeout(() => {
                this.layoutChanges++;
            }, 250);
        },
        isPartnerCustomisationEnabled(): boolean {
            return isCustomisationEnabled();
        },
    },
});
