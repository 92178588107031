
import Vue from "vue";

import LatestStationReadings from "@/views/shared/LatestStationReadings.vue";
import PaginationControls from "@/views/shared/PaginationControls.vue";
import { DisplayProject, DisplayStation } from "@/store";

export default Vue.extend({
    name: "StationsReadings",
    components: {
        LatestStationReadings,
        PaginationControls,
    },
    data(): {
        index: number;
    } {
        return {
            index: 0,
        };
    },
    props: {
        displayProject: {
            type: Object,
            required: true,
        },
    },
    computed: {
        stations(): DisplayStation[] {
            return this.displayProject.stations;
        },
        visibleStation(): DisplayStation | null {
            if (this.displayProject.stations.length > 0) {
                return this.displayProject.stations[this.index];
            }
            return null;
        },
    },
    methods: {
        onNewStation(index: number): void {
            this.index = index;
        },
    },
});
