
import Vue, { PropType } from "vue";
import StationPicker from "./StationPicker.vue";
import { DisplayStation } from "@/store";

export default Vue.extend({
    name: "StationPickerModal",
    components: {
        StationPicker,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        actionType: {
            type: String,
            required: true,
        },
        actionText: {
            type: String,
            required: true,
        },
        stations: {
            type: Array as PropType<DisplayStation[]>,
            required: true,
        },
        filter: {
            type: Function as PropType<(station: DisplayStation) => boolean>,
            default: (station) => true,
        },
    },
    methods: {
        emitAction(ids): void {
            this.$emit(this.actionType, ids);
        },
    },
});
