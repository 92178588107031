
import _ from "lodash";
import Vue from "vue";

export default Vue.extend({
    name: "PaginationControls",
    props: {
        page: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        maximumPages: {
            type: Number,
            default: 7,
        },
        textual: {
            type: Boolean,
            default: false,
        },
        wrap: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        pages(this: any) {
            return _.range(0, this.totalPages).map((p) => {
                return {
                    selected: this.page == p,
                    number: p,
                };
            });
        },
        canPageNext(this: any) {
            if (this.wrap) {
                return true;
            } else {
                return this.page < this.totalPages - 1;
            }
        },
        canPagePrevious(this: any) {
            if (this.wrap) {
                return true;
            } else {
                return this.page > 0;
            }
        },
    },
    methods: {
        onPrevious() {
            if (this.wrap && this.page <= 0) {
                this.$emit("new-page", this.totalPages - 1);
            }
            if (this.page > 0) {
                this.$emit("new-page", this.page - 1);
            }
        },
        onNext() {
            if (this.wrap && this.page >= this.totalPages - 1) {
                this.$emit("new-page", 0);
            }
            if (this.page < this.totalPages - 1) {
                this.$emit("new-page", this.page + 1);
            }
        },
        onPage(this: any, page: number) {
            this.$emit("new-page", page);
        },
    },
});
