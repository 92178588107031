function getConfig() {
    if (/fkdev.org/.test(window.location.href)) {
		return {
			baseUrl: "https://api.fkdev.org",
			vuexLogging: false,
			sso: false,
			backend: null,
			partners: {
				hostOverride: null,
			},
			auth: {
				logoutUrl: "https://auth.fkdev.org/auth/realms/fkdev/protocol/openid-connect/logout?redirect_uri=https://staging.fieldkit.org",
			},
			mapbox: {
				token: "pk.eyJ1Ijoiamxld2FsbGVuIiwiYSI6ImNqdTFmZmtuZjAxM2g0NHBhM2YwZjJ1eXoifQ.2a78TGwkWRsCPd2Ovph7WQ",
				style: "mapbox://styles/jlewallen/ckv1deyqf0t8714mxnpf71ig1"
			},
			tncDate: 1626815015000
		};
    }

	return {
		baseUrl: "https://api.fieldkit.org",
		vuexLogging: false,
		sso: false,
		backend: null,
		partners: {
			hostOverride: null,
		},
		auth: {
			logoutUrl: "https://auth.fieldkit.org/auth/realms/fkprod/protocol/openid-connect/logout?redirect_uri=https://www.fieldkit.org",
		},
		mapbox: {
			token: "pk.eyJ1Ijoiamxld2FsbGVuIiwiYSI6ImNrNnNrNTh0OTBobmEzbHJ6dXlvODM5dnEifQ.1D9IA3musIpqnYn5OHtnMw",
			style: "mapbox://styles/jlewallen/ckv1deyqf0t8714mxnpf71ig1"
		},
		tncDate: 1626815015000
	};
}

export default getConfig();
