
import Vue from "vue";
import StandardLayout from "../StandardLayout.vue";
import CommonComponents from "@/views/shared";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default Vue.extend({
    name: "AdminMain",
    components: {
        StandardLayout,
        ...CommonComponents,
    },
    props: {},
    data: () => {
        return {
            deletionForm: {
                email: "",
                password: "",
            },
            tncForm: {
                email: "",
            },
            deletion: {
                success: false,
                failed: false,
            },
            tnc: {
                success: false,
                failed: false,
            },
        };
    },
    validations: {
        deletionForm: {
            email: {
                required,
                email,
            },
            password: {
                required,
                min: minLength(10),
            },
        },
        tncForm: {
            email: {
                required,
                email,
            },
        },
    },
    methods: {
        deleteUser(this: any) {
            this.$v.deletionForm.$touch();
            if (this.$v.deletionForm.$pending || this.$v.deletionForm.$error) {
                return;
            }

            return this.$confirm({
                message: `Are you sure? This operation cannot be undone.`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        return this.$services.api.adminDeleteUser(this.form).then(
                            () => {
                                this.deletion.success = true;
                                this.deletion.failed = false;
                            },
                            () => {
                                this.deletion.failed = true;
                                this.deletion.success = false;
                            }
                        );
                    }
                },
            });
        },
        clearTermsAndConditions(this: any) {
            this.$v.tncForm.$touch();
            if (this.$v.tncForm.$pending || this.$v.tncForm.$error) {
                return;
            }

            return this.$confirm({
                message: `Are you sure? This operation cannot be undone.`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        return this.$services.api.adminClearTermsAndConditions(this.tncForm).then(
                            () => {
                                this.tnc.success = true;
                                this.tnc.failed = false;
                            },
                            () => {
                                this.tnc.failed = true;
                                this.tnc.success = false;
                            }
                        );
                    }
                },
            });
        },
    },
});
