
import Vue from "vue";

export enum Locales {
    enUS = "en-US",
    esEs = "es-ES",
}

export default Vue.extend({
    name: "LanguageSelector",
    data() {
        return {
            Locales: Locales,
        };
    },
    methods: {
        changeLang(locale: Locales) {
            this.$i18n.locale = locale;
            localStorage.setItem("locale", locale);
        },
    },
});
