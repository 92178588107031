
import Vue from "vue";
import CommonComponents from "@/views/shared";
import AudioPlayer from "./AudioPlayer.vue";

export default Vue.extend({
    model: {
        prop: "note",
        event: "change",
    },
    name: "NoteEditor",
    components: {
        ...CommonComponents,
        AudioPlayer,
    },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        note: {
            type: Object,
            required: true,
        },
        v: {
            type: Object,
            required: true,
        },
        editableTitle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editingTitle: false,
        };
    },
    computed: {
        body: {
            get(this: any) {
                return this.note.body;
            },
            set(this: any, value) {
                this.$emit("change", this.note.withBody(value, this.note.title));
            },
        },
        title: {
            get(this: any) {
                if (this.editingTitle) {
                    return this.note.title;
                }
                return this.note.title || this.note.help.title;
            },
            set(this: any, value) {
                this.$emit("change", this.note.withBody(this.note.body, value));
            },
        },
    },
});
