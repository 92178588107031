
import _ from "lodash";
import Vue from "vue";

import { ExploreContext } from "@/views/viz/common";

import { BoundingRectangle, DisplayProject, DisplayStation, MappedStations } from "@/store";
import * as ActionTypes from "@/store/actions";

import StationHoverSummary from "@/views/shared/StationHoverSummary.vue";
import StationsMap from "@/views/shared/StationsMap.vue";
import StationPickerModal from "@/views/shared/StationPickerModal.vue";
import TinyStation from "@/views/shared/TinyStation.vue";
import PaginationControls from "@/views/shared/PaginationControls.vue";
import { StationPickerActionType } from "@/views/shared/StationPicker.vue";
import StationOrSensor from "@/views/shared/partners/StationOrSensor.vue";
import { interpolatePartner } from "@/views/shared/partners";

import { SensorDataQuerier } from "@/views/shared/sensor_data_querier";

export default Vue.extend({
    name: "ProjectStations",
    components: {
        StationHoverSummary,
        StationPickerModal,
        StationsMap,
        PaginationControls,
        TinyStation,
        StationOrSensor,
    },
    data(): {
        activeStationId: number | null;
        layoutChanges: number;
        showStationsPanel: boolean;
        addingStation: boolean;
        editingStation: boolean;
        page: number;
        pageSize: number;
        StationPickerActionType: any;
    } {
        return {
            activeStationId: null,
            layoutChanges: 0,
            showStationsPanel: true,
            addingStation: false,
            editingStation: false,
            page: 0,
            pageSize: 4,
            StationPickerActionType: StationPickerActionType,
        };
    },
    props: {
        project: {
            type: Object as () => DisplayProject,
            required: true,
        },
        admin: {
            type: Boolean,
            required: true,
        },
        userStations: {
            type: Array as () => DisplayStation[],
            required: true,
        },
    },
    watch: {
        project(): void {
            this.activeStationId = null;
        },
    },
    computed: {
        projectStations(): DisplayStation[] {
            return this.$getters.projectsById[this.project.id].stations;
        },
        mappedProject(): MappedStations | null {
            return this.$getters.projectsById[this.project.id].mapped;
        },
        activeStation(): DisplayStation | null {
            if (this.activeStationId) {
                return this.$getters.stationsById[this.activeStationId];
            }
            return null;
        },
        visibleStations(): DisplayStation[] {
            if (!this.projectStations) {
                return [];
            }
            const start = this.page * this.pageSize;
            const end = start + this.pageSize;
            return this.projectStations.slice(start, end);
        },
        sensorDataQuerier(): SensorDataQuerier {
            return new SensorDataQuerier(this.$services.api);
        },
        totalPages(): number {
            if (this.projectStations) {
                return Math.ceil(this.projectStations.length / this.pageSize);
            }
            return 0;
        },
        mapBounds(): BoundingRectangle {
            if (this.project.bounds?.min && this.project.bounds?.max) {
                return new BoundingRectangle(this.project.bounds?.min, this.project.bounds?.max);
            }

            return MappedStations.defaultBounds();
        },
        exploreContext(): ExploreContext {
            return new ExploreContext(this.project.id);
        },
    },
    methods: {
        pickFilter(station: DisplayStation): boolean {
            const excluding = _.map(this.projectStations, (s) => s.id);
            return excluding.indexOf(station.id) < 0;
        },
        showStation(station: DisplayStation): Promise<any> {
            // All parameters are strings.
            return this.$router.push({ name: "mapStation", params: { id: String(station.id) } });
        },
        showAddStationPicker(): void {
            this.addingStation = true;
        },
        showEditStationPicker(): void {
            this.editingStation = true;
        },
        onStationAdd(stationIds): void {
            this.addingStation = false;
            stationIds.forEach((stationId) => {
                const payload = {
                    projectId: this.project.id,
                    stationId: stationId,
                };
                this.$store.dispatch(ActionTypes.STATION_PROJECT_ADD, payload);
            });
        },
        onStationRemove(stationIds): void {
            this.editingStation = false;
            stationIds.forEach((stationId) => {
                const payload = {
                    projectId: this.project.id,
                    stationId: stationId,
                };
                this.$store.dispatch(ActionTypes.STATION_PROJECT_REMOVE, payload);
            });
        },
        onCloseAddStationModal(): void {
            this.addingStation = false;
        },
        onCloseEditStationModal(): void {
            this.editingStation = false;
        },
        showSummary(station: DisplayStation): void {
            console.log("showSummay", station);
            this.activeStationId = station.id;
        },
        removeStation(this: any, station: DisplayStation): Promise<any> {
            console.log("remove", station);
            if (!window.confirm("Are you sure you want to remove this station?")) {
                return Promise.resolve();
            }
            const payload = {
                projectId: this.project.id,
                stationId: station.id,
            };
            return this.$store.dispatch(ActionTypes.STATION_PROJECT_REMOVE, payload);
        },
        openNotes(this: any, station: DisplayStation): Promise<any> {
            return this.$router.push({
                name: "viewStation",
                params: {
                    projectId: this.project.id,
                    stationId: station.id,
                },
            });
        },
        onCloseSummary(): void {
            this.activeStationId = null;
        },
        toggleStationsPanel(): void {
            this.layoutChanges++;
            this.showStationsPanel = !this.showStationsPanel;
            console.log("toggle", this.showStationsPanel, this.layoutChanges);
        },
        onNewPage(page: number): void {
            this.page = page;
        },
        interpolatePartner(baseString) {
            return interpolatePartner(baseString);
        },
    },
});
