
import Vue from "vue";
import StandardLayout from "../StandardLayout.vue";
import CommonComponents from "@/views/shared";
import PaginationControls from "@/views/shared/PaginationControls.vue";
import FKApi, { Station, SimpleUser, EssentialStation } from "@/api/api";
import { BookmarkFactory, serializeBookmark } from "@/views/viz/viz";
import TransferStation from "./TransferStation.vue";
import { Buffer } from "buffer";

export default Vue.extend({
    name: "AdminStations",
    components: {
        ...CommonComponents,
        StandardLayout,
        PaginationControls,
        TransferStation,
    },
    props: {},
    data(): {
        stations: EssentialStation[];
        page: number;
        pageSize: number;
        totalPages: number;
        focused: Station | null;
    } {
        return {
            stations: [],
            page: 0,
            pageSize: 50,
            totalPages: 0,
            focused: null,
        };
    },
    mounted(this: any) {
        return this.refresh();
    },
    methods: {
        async selected(station: EssentialStation): Promise<void> {
            if (this.focused && this.focused.id == station.id) {
                return;
            }
            this.focused = null;
            this.focused = await this.$services.api.getStation(station.id);
        },
        async refresh(): Promise<void> {
            await this.$services.api.getAllStations(this.page, this.pageSize).then((page) => {
                this.totalPages = Math.ceil(page.total / this.pageSize);
                this.stations = page.stations;
            });
        },
        onNewPage(page: number): Promise<void> {
            this.page = page;
            return this.refresh();
        },
        async deleteStation(station: EssentialStation): Promise<void> {
            await this.$confirm({
                message: `Are you sure? This operation cannot be undone.`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        return this.$services.api.deleteStation(station.id).then(() => {
                            return this.refresh();
                        });
                    }
                },
            });
        },
        urlForServerLogs(station: EssentialStation): string {
            const deviceIdBase64 = Buffer.from(station.deviceId, "hex").toString("base64");
            const deviceIdHex = station.deviceId;
            const query = `device_id:("${deviceIdBase64}" or "${deviceIdHex}")`;
            return "https://code.conservify.org/logs-viewer/?range=864000&query=" + encodeURIComponent(query);
        },
        async onProcessUpload(upload: { id: number }): Promise<void> {
            await this.$confirm({
                message: `Are you sure? This could take a while for certain files.`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$services.api.adminProcessUpload(upload.id);
                    }
                },
            });
        },
        async onProcessData(station: EssentialStation): Promise<void> {
            await this.$confirm({
                message: `Are you sure? This could take a while for certain stations.`,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$services.api.adminProcessStationData(station.id);
                    }
                },
            });
        },
        onTransferred(station: EssentialStation, user: SimpleUser): void {
            station.owner = user;
        },
        async onExplore(station: EssentialStation): Promise<void> {
            const bm = BookmarkFactory.forStation(station.id);
            await this.$router.push({ name: "exploreBookmark", query: { bookmark: serializeBookmark(bm) } });
        },
    },
});
