
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import * as utils from "../../utilities";
import { DisplayProject, DisplayStation, Project, ProjectModule } from "@/store";
import ProjectStations from "./ProjectStations.vue";
import CommonComponents from "@/views/shared";
import Comments from "../comments/Comments.vue";
import FollowControl from "@/views/shared/FollowControl.vue";
import { twitterCardMeta } from "@/social";

export default Vue.extend({
    name: "ProjectPublic",
    components: {
        Comments,
        ...CommonComponents,
        ProjectStations,
        FollowControl,
    },
    data: () => {
        return {};
    },
    metaInfo() {
        return {
            meta: twitterCardMeta(this.displayProject),
            afterNavigation() {
                console.log("hello: after-navigation");
            },
        };
    },
    props: {
        user: {
            required: true,
        },
        userStations: {
            type: Array as PropType<DisplayStation[]>,
            required: true,
        },
        displayProject: {
            type: Object as PropType<DisplayProject>,
            required: true,
        },
    },
    computed: {
        ...mapGetters({ isAuthenticated: "isAuthenticated", isBusy: "isBusy" }),
        project(): Project {
            return this.displayProject.project;
        },
        projectStations(): DisplayStation[] {
            return this.$getters.projectsById[this.displayProject.id].stations;
        },
        projectModules(): { name: string; url: string }[] {
            return this.$getters.projectsById[this.displayProject.id].modules.map((m) => {
                return {
                    name: m.name,
                    url: this.getModuleImg(m),
                };
            });
        },
    },
    methods: {
        getModuleImg(module: ProjectModule): string {
            return this.$loadAsset(utils.getModuleImg(module));
        },
        getTeamHeading(): string {
            // TODO i18n
            const members = this.displayProject.users.length == 1 ? "member" : "members";
            return "Project Team (" + this.displayProject.users.length + " " + members + ")";
        },
    },
});
