
import _ from "lodash";
import Vue, { PropType } from "vue";
import CommonComponents from "@/views/shared";
import moment from "moment";
import { DataEventsErrorsEnum, NewComment, NewDataEvent } from "@/views/comments/model";
import { Comment, DataEvent, DiscussionBase } from "@/views/comments/model";
import { CurrentUser, ProjectUser } from "@/api";
import { CommentsErrorsEnum } from "@/views/comments/model";
import ListItemOptions from "@/views/shared/ListItemOptions.vue";
import Tiptap from "@/views/shared/Tiptap.vue";
import { deserializeBookmark, Workspace } from "../viz/viz";
import SectionToggle from "@/views/shared/SectionToggle.vue";
import { Bookmark } from "@/views/viz/viz";
import { TimeRange } from "@/views/viz/common";
import { ActionTypes, DisplayProject } from "@/store";
import { interpolatePartner, isCustomisationEnabled } from "@/views/shared/partners";
import InfoTooltip from "@/views/shared/InfoTooltip.vue";
import { PortalStationFieldNotes } from "@/views/fieldNotes/model";
import { SnackbarStyle } from "@/store/modules/snackbar";

export default Vue.extend({
    name: "Comments",
    components: {
        ...CommonComponents,
        ListItemOptions,
        Tiptap,
        SectionToggle,
        InfoTooltip,
    },
    props: {
        user: {
            type: Object as PropType<CurrentUser>,
            required: false,
        },
        parentData: {
            type: [Object, Number],
            required: true,
        },
        workspace: {
            type: Workspace,
            required: false,
        },
    },
    data(): {
        posts: Comment[];
        dataEvents: DataEvent[];
        isLoading: boolean;
        placeholder: string | null;
        viewType: string;
        newComment: {
            projectId: number | null;
            bookmark: string | null;
            body: string | null;
        };
        newReply: {
            projectId: number | null;
            bookmark: string | null;
            body: string | null;
            threadId: number | null;
        };
        newDataEvent: {
            allProjectSensors: boolean;
            bookmark: string | null;
            description: string | null;
            title: string | null;
        };
        logMode: string;
    } {
        return {
            posts: [],
            dataEvents: [],
            isLoading: true,
            placeholder: null,
            viewType: typeof this.$props.parentData === "number" ? "project" : "data",
            newComment: {
                projectId: typeof this.parentData === "number" ? this.parentData : null,
                bookmark: null,
                body: "",
            },
            newReply: {
                projectId: typeof this.parentData === "number" ? this.parentData : null,
                bookmark: null,
                body: "",
                threadId: null,
            },
            newDataEvent: {
                allProjectSensors: true,
                bookmark: null,
                description: "",
                title: "",
            },
            logMode: "comment",
        };
    },
    computed: {
        ActionTypes() {
            return ActionTypes;
        },
        projectId(): number {
            if (this.parentData instanceof Bookmark) {
                return this.parentData.p[0];
            }
            return this.parentData;
        },
        stationId(): number | null {
            if (this.parentData instanceof Bookmark) {
                return this.parentData.s[0];
            }
            return null;
        },
        isAdmin(): boolean {
            if (this.user.id && this.projectId) {
                return this.$store.getters.isAdminForProject(this.user.id, this.projectId);
            }
            return false;
        },
        // we need it in order to see if the user is an admin and can delete posts
        isProjectLoaded(): boolean {
            if (this.projectId) {
                const project = this.$getters.projectsById[this.projectId];
                if (!project) {
                    this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: this.projectId });
                }
                return !!this.$getters.projectsById[this.projectId];
            }
            return false;
        },
        dataEventsFromState(): DataEvent[] {
            return this.$state.discussion.dataEvents;
        },
        postsAndEvents(): DiscussionBase[] {
            return [...this.posts, ...this.dataEvents].sort(this.sortRecent);
        },
        projectUser(): ProjectUser | null {
            const projectId = this.parentData instanceof Bookmark ? this.parentData.p[0] : null;

            if (projectId) {
                const displayProject = this.$getters.projectsById[projectId];
                return displayProject?.users?.filter((user) => user.user.id === this.user?.id)[0];
            }

            return null;
        },
        stationBelongsToAProject(): boolean {
            if (this.parentData instanceof Bookmark) {
                return !!this.parentData.p?.length;
            }
            return false;
        },
        parentNumber(): number | null {
            if (_.isNumber(this.parentData)) {
                return this.parentData;
            }
            return null;
        },
        parentBookmark(): Bookmark | null {
            if (this.parentData instanceof Bookmark) {
                return this.parentData;
            }
            return null;
        },
    },
    watch: {
        async parentData(): Promise<void> {
            await this.getDataEvents();
            return this.getComments();
        },
        async $route(): Promise<void> {
            await this.getComments();
            this.highlightComment();
        },
        dataEventsFromState(): void {
            this.initDataEvents();
        },
    },
    async mounted(): Promise<void> {
        const projectId = this.parentData instanceof Bookmark ? this.parentData.p[0] : null;

        if (projectId) {
            await this.$store.dispatch(ActionTypes.NEED_PROJECT, { id: projectId });
            await this.$getters.projectsById[projectId];
        }
        this.placeholder = this.getNewCommentPlaceholder();
        this.newDataEvent.allProjectSensors = this.stationBelongsToAProject;

        await this.getDataEvents();
        return this.getComments();
    },
    methods: {
        getNewCommentPlaceholder(): string {
            if (this.viewType === "project") {
                return "Comment on Project";
            } else {
                return "Write a comment about this Data View";
            }
        },
        async saveDataEvent(dataEvent: NewDataEvent): Promise<void> {
            const bookmark = this.parentBookmark;
            if (bookmark != null) {
                if (this.viewType === "data") {
                    dataEvent.bookmark = JSON.stringify(bookmark);
                }

                const timeRange: TimeRange = bookmark.allTimeRange;
                dataEvent.start = timeRange.start;
                dataEvent.end = timeRange.end;
            }

            await this.$services.api
                .postDataEvent(dataEvent)
                .then((response) => {
                    if (response) {
                        this.newDataEvent.title = "";
                        this.newDataEvent.description = "";

                        this.getDataEvents();
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("comments.dataEventSuccess"),
                            type: SnackbarStyle.success,
                        });
                        this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "newDataEventTitle");
                        this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "newDataEventDesc");
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        async save(comment: NewComment, dirtyInputId: string): Promise<void> {
            if (this.viewType === "data") {
                comment.bookmark = JSON.stringify(this.parentData);
            }

            await this.$services.api
                .postComment(comment)
                .then((response: { post: Comment }) => {
                    this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, dirtyInputId);
                    // add the comment to the replies array
                    if (comment.threadId) {
                        if (this.posts) {
                            this.posts
                                .filter((post) => post.id === comment.threadId)[0]
                                .replies.push(
                                    new Comment(
                                        response.post.id,
                                        response.post.author,
                                        response.post.bookmark,
                                        response.post.body,
                                        response.post.createdAt,
                                        response.post.updatedAt
                                    )
                                );
                            this.resetNewReply();
                        } else {
                            console.warn(`posts is null`);
                        }
                    } else {
                        // add it to the posts array
                        if (this.posts) {
                            this.posts.unshift(
                                new Comment(
                                    response.post.id,
                                    response.post.author,
                                    response.post.bookmark,
                                    response.post.body,
                                    response.post.createdAt,
                                    response.post.updatedAt
                                )
                            );
                            this.newComment.body = "";
                        } else {
                            console.log(`posts is null`);
                        }
                    }
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("comments.saveSuccess"),
                        type: SnackbarStyle.success,
                    });
                })
                .catch((e) => {
                    console.log("e", e);
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        formatTimestamp(timestamp: number): string {
            return moment(timestamp).fromNow();
        },
        addReply(post: Comment): void {
            if (this.newReply.body && post.id === this.newReply.threadId) {
                return;
            }
            this.newReply.threadId = post.id;
            this.newReply.body = "";
        },
        async getComments(): Promise<void> {
            const queryParam = this.parentNumber ? this.parentNumber : this.parentBookmark;
            if (!queryParam) {
                return;
            }
            this.isLoading = true;
            await this.$services.api
                .getComments(queryParam)
                .then((data) => {
                    this.posts = [];
                    data.posts.forEach((post) => {
                        this.posts.push(new Comment(post.id, post.author, post.bookmark, post.body, post.createdAt, post.updatedAt));

                        post.replies.forEach((reply) => {
                            this.posts[this.posts.length - 1].replies.push(
                                new Comment(reply.id, reply.author, reply.bookmark, reply.body, reply.createdAt, reply.updatedAt)
                            );
                        });
                    });

                    this.highlightComment();
                })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        viewDataClick(post: Comment) {
            if (post.bookmark) {
                this.$emit("viewDataClicked", deserializeBookmark(post.bookmark));
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        },
        deleteComment(commentID: number) {
            this.$services.api
                .deleteComment(commentID)
                .then((response) => {
                    if (response) {
                        this.getComments();
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("comments.deleteSuccess"),
                            type: SnackbarStyle.success,
                        });
                    } else {
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("somethingWentWrong"),
                            type: SnackbarStyle.fail,
                        });
                    }
                })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        startEditing(item: Comment | DataEvent): void {
            item.readonly = false;
        },
        saveEdit(commentID: number, body: Record<string, unknown>, dirtyInputId: string) {
            this.$services.api
                .editComment(commentID, body)
                .then((response) => {
                    if (response) {
                        this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, dirtyInputId);
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("comments.saveSuccess"),
                            type: SnackbarStyle.success,
                        });
                        this.getComments();
                    } else {
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("somethingWentWrong"),
                            type: SnackbarStyle.fail,
                        });
                    }
                })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        async getDataEvents(): Promise<void> {
            if (typeof this.parentData === "number") {
                this.dataEvents = [];
                return;
            }
            this.isLoading = true;
            await this.$store
                .dispatch(ActionTypes.NEED_DATA_EVENTS, { bookmark: JSON.stringify(this.parentData) })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        initDataEvents(): void {
            this.dataEvents = [];
            this.dataEventsFromState.forEach((event) => {
                this.dataEvents.push(
                    new DataEvent(
                        event.id,
                        event.author,
                        event.bookmark,
                        event.createdAt,
                        event.updatedAt,
                        event.title ? JSON.parse(event.title) : event.title,
                        event.description ? JSON.parse(event.description) : event.description,
                        event.start,
                        event.end
                    )
                );
            });
        },
        saveEditDataEvent(dataEvent: DataEvent): Promise<void> {
            return this.$services.api
                .updateDataEvent(dataEvent)
                .then((response) => {
                    if (response) {
                        this.newDataEvent.title = "";
                        this.newDataEvent.description = "";
                        this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editEventDesc#" + dataEvent.id);
                        this.getDataEvents();
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("comments.dataEventSuccess"),
                            type: SnackbarStyle.success,
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        deleteDataEvent(dataEventID: number): Promise<void> {
            return this.$services.api
                .deleteDataEvent(dataEventID)
                .then((response) => {
                    if (response) {
                        this.getDataEvents();
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("comments.dataEventDeleteSuccess"),
                            type: SnackbarStyle.success,
                        });
                    } else {
                        this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                            message: this.$tc("somethingWentWrong"),
                            type: SnackbarStyle.fail,
                        });
                    }
                })
                .catch(() => {
                    this.$store.dispatch(ActionTypes.SHOW_SNACKBAR, {
                        message: this.$tc("somethingWentWrong"),
                        type: SnackbarStyle.fail,
                    });
                });
        },
        onListItemOptionClick(event: string, item: Comment | DataEvent): void {
            if (event === "edit-comment") {
                this.startEditing(item);
            }
            if (event === "delete-comment") {
                if (item.type === "comment") {
                    this.deleteComment(item.id);
                }
                if (item.type === "event") {
                    this.deleteDataEvent(item.id);
                }
            }
        },
        getCommentOptions(post: Comment): { label: string; event: string }[] {
            if (!this.user) {
                return [];
            }

            if (this.user.id === post.author.id) {
                return [
                    {
                        label: "Edit post",
                        event: "edit-comment",
                    },
                    {
                        label: "Delete post",
                        event: "delete-comment",
                    },
                ];
            }

            if (this.isAdmin) {
                return [
                    {
                        label: "Delete post",
                        event: "delete-comment",
                    },
                ];
            }

            return [];
        },
        highlightComment(): void {
            this.$nextTick(() => {
                if (location.hash) {
                    const el = document.querySelector(location.hash);

                    if (el) {
                        el.scrollIntoView({ behavior: "smooth", block: "center" });
                        el.classList.add("highlight");
                        setTimeout(() => {
                            el.classList.remove("highlight");
                        }, 5000);
                    }
                }
            });
        },
        onSectionToggle(evt): void {
            if (evt === "left") {
                this.logMode = "comment";
            }
            if (evt === "right") {
                this.logMode = "event";
            }
        },
        sortRecent(a, b): any {
            return b.createdAt - a.createdAt;
        },
        interpolatePartner(baseString): string {
            return interpolatePartner(baseString);
        },
        // don't allow the user to log an event if the viz group has no data, by simply hiding the Event logging toggle
        areWorkspaceGroupsEmpty(): boolean {
            let areEmpty = false;

            if (this.workspace) {
                this.workspace.groups.forEach((group) => {
                    if (group.isEmpty()) {
                        areEmpty = true;
                    }
                });
            }

            return areEmpty;
        },
        showPostsTypeToggle(): boolean {
            return (
                (((this.user && this.user.admin) ||
                    (this.projectUser && this.projectUser.user && this.projectUser.role === "Administrator")) &&
                    !this.areWorkspaceGroupsEmpty()) ||
                false
            );
        },
        onEditCommentInput(comment: any, event: string) {
            if (JSON.stringify(event) !== comment.body) {
                this.$store.dispatch(ActionTypes.NEW_DIRTY_FIELD, "editComment#" + comment.id);
            } else {
                this.$store.dispatch(ActionTypes.CLEAR_DIRTY_FIELD, "editComment#" + comment.id);
            }
        },
        resetNewReply() {
            this.newReply = {
                ...this.newReply,
                body: null,
                threadId: null,
            };
        },
    },
});
