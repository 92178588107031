
import _ from "lodash";
import Vue from "vue";
import { ResizeAuto } from "./ResizeAuto";
import { Editor, JSONContent, EditorContent, VueRenderer, Extension } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import MentionList from "../comments/MentionList.vue";
import tippy, { Props } from "tippy.js";
import { CharacterCount } from "@tiptap/extension-character-count";

export default Vue.extend({
    name: "TipTap",
    components: {
        // ResizeAuto,
        EditorContent,
    },
    props: {
        value: {
            // type: Object as PropType<JSONContent | string>,
            required: true,
        },
        characters: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        showSaveButton: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        saveLabel: {
            type: String,
            default: "Post",
        },
        disableMentions: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        editor: Editor | null;
        seeMore: boolean;
        seeLess: boolean;
    } {
        return {
            editor: null,
            seeMore: false,
            seeLess: false,
        };
    },
    watch: {
        readonly(value: boolean): void {
            if (this.editor) {
                this.editor.setOptions({ editable: !value });
            }
        },
        value(value: string): void {
            if (this.editor) {
                if (JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)) return;
                this.editor.commands.setContent(this.asContent(value));
            }
        },
    },
    computed: {
        empty(): boolean {
            return this.editor == null || this.editor.storage.characterCount.characters() == 0;
        },
    },
    mounted() {
        const services = this.$services;

        const changed = (editor) => {
            this.$emit("input", editor.getJSON());

            if (editor.isEmpty) {
                this.$emit("empty", true);
            }
        };
        const saved = (editor, ...args) => {
            if (!editor.isEmpty) {
                this.$emit("save", editor.getJSON());
                editor.commands.clearContent();
            }
        };

        const CustomNewLine = Extension.create({
            name: "newline",
            addCommands() {
                return {
                    addNewline:
                        () =>
                        ({ state, dispatch }) => {
                            const { schema, tr } = state;
                            const paragraph = schema.nodes.paragraph;

                            const transaction = tr.deleteSelection().replaceSelectionWith(paragraph.create(), true).scrollIntoView();
                            if (dispatch) dispatch(transaction);
                            return true;
                        },
                } as never;
            },
            addKeyboardShortcuts() {
                return {
                    "Shift-Enter": () => (this.editor.commands as any).addNewline(),
                };
            },
        });

        const ModifyEnter = Extension.create({
            addKeyboardShortcuts() {
                return {
                    Enter: (...args) => {
                        saved(this.editor);
                        // return true prevents default behaviour
                        return true;
                    },
                };
            },
        });

        // eslint-disable-next-line
        const thisComp = this;

        const extensions = [Document, Paragraph, Text, ModifyEnter, CustomNewLine, CharacterCount];

        if (!this.disableMentions) {
            extensions.push(
                Mention.configure({
                    HTMLAttributes: {
                        class: "mention",
                    },
                    suggestion: {
                        items: (props: { query: string; editor: Editor }): any[] => {
                            if (props.query.length > 0) {
                                return services.api.mentionables(props.query).then((mentionables) => {
                                    console.log("mentionables", mentionables);
                                    return mentionables.users;
                                }) as unknown as any[];
                            } else {
                                return Promise.resolve([]) as unknown as any[];
                            }
                        },
                        render: () => {
                            let component;
                            let popup;

                            return {
                                onStart: (props) => {
                                    console.log("mentions-start", props);

                                    component = new VueRenderer(MentionList, {
                                        parent: this,
                                        propsData: props,
                                    });

                                    const newProps: Partial<Props> = {
                                        getReferenceClientRect: null,
                                        appendTo: () => document.body,
                                        content: component.element,
                                        showOnCreate: true,
                                        interactive: true,
                                        trigger: "manual",
                                        placement: "bottom-start",
                                    };

                                    const clientRect = props.clientRect;
                                    if (clientRect) {
                                        newProps.getReferenceClientRect = () => {
                                            const rect = clientRect();
                                            if (!rect) {
                                                throw new Error();
                                            }
                                            return rect;
                                        };
                                    }

                                    popup = tippy("body", newProps);
                                },
                                onUpdate(props) {
                                    console.log("mentions-update", props);

                                    component.updateProps(props);

                                    popup[0].setProps({
                                        getReferenceClientRect: props.clientRect,
                                    });
                                },
                                onKeyDown(props) {
                                    return component.ref?.onKeyDown(props);
                                },
                                onExit() {
                                    popup[0].destroy();
                                    component.destroy();
                                },
                            };
                        },
                    },
                })
            );
        }

        this.editor = new Editor({
            editable: !this.readonly,
            content: this.asContent(this.value),
            extensions: extensions,
            onUpdate({ editor }) {
                changed(editor);
            },
            onBlur({ editor }) {
                console.log("editor-blur");
            },
            onFocus({ editor }) {
                console.log("editor-focus");
                thisComp.$emit("editor-focus");
            },
        });

        setTimeout(() => {
            this.truncate();
        });
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
    },
    methods: {
        onChange(...args) {
            console.log("on-change", args);
        },
        onSave() {
            if (this.editor && !this.editor.isEmpty) {
                this.$emit("save");
                this.editor.commands.clearContent();
            }
        },
        truncate() {
            if (!this.readonly) {
                return false;
            }

            const contentContainerEl = this.$refs.contentContainer as HTMLElement;

            if (contentContainerEl.offsetHeight < contentContainerEl.scrollHeight) {
                this.seeMore = true;
                contentContainerEl.classList.add("truncated");
            } else {
                this.seeMore = false;
                contentContainerEl.classList.remove("truncated");
            }
        },
        toggleSeeMore(show: boolean) {
            const contentContainerEl = this.$refs.contentContainer as HTMLElement;
            contentContainerEl.classList.toggle("truncated");
            this.seeMore = !show;
            this.seeLess = show;
        },
        asContent(v: unknown): JSONContent | null {
            if (_.isString(v)) {
                if (v.length == 0) {
                    return null;
                }
                return JSON.parse(v);
            }
            return v as JSONContent;
        },
    },
});
