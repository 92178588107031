import { render, staticRenderFns } from "./ProjectView.vue?vue&type=template&id=26221f2d&scoped=true&"
import script from "./ProjectView.vue?vue&type=script&lang=ts&"
export * from "./ProjectView.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectView.vue?vue&type=style&index=0&id=26221f2d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26221f2d",
  null
  
)

export default component.exports